@import '~@scss/functions';

.formLayout {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	.row {
		display: flex;
		width: 100%;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: stretch;
		box-sizing: border-box;
	}

	.rowWithDoubleCol {
		display: flex;
		width: 100%;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: stretch;
		box-sizing: border-box;
	}

	.col {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		&.labelCol {
			flex-basis: rem(199px);
			min-width: 0; // used to prevent content overflow flex parent
		}

		&.inputCol {
			flex-basis: rem(366px);
			display: flex; // used to prevent content overflow flex parent
			min-width: 0; // used to prevent content overflow flex parent
		}

		.contentWrapper,.subContentPlaceholder { // used to prevent content overflow flex parent
			display: flex;
			height: 100%;
		}
	}

	.colSpace {
		display: block;
		width: rem(30px);
	}

	.colWithDoubleInput {
		display: flex;
		max-width: rem(366px);
	}
}