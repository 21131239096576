@import '~@scss/functions';

.metricLayout {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	border-top: rem(1px) solid #d4e2f1;

	&_borderless {
		border: none;
	}

	.metric {
		flex: 1;
		min-width: 50%;
		max-width: 50%;
		width: 100%;
		position: relative;
		padding: rem(35px) rem(12px) rem(24px);

		&__loadingBlock {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: rem(265px);
			min-width: rem(530px);
		}

		&__loadingIcon {
			margin-bottom: rem(16px);
		}

		&__loadingText {
			color: #000000;
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			white-space: nowrap;
		}

		.metricWrapper {
			position: relative;
			height: rem(210px);
		}

		&:nth-child(odd) {
			padding-right: rem(30px);
			border-right: rem(1px) solid #d4e2f1;
		}

		&:nth-child(even) {
			padding-left: rem(40px);
		}

		&:nth-child(n - 2) {
			border-bottom: rem(1px) solid #d4e2f1;
		}

		&__title {
			letter-spacing: rem(0.16px);
			font-weight: 600;
			color: #000;
			margin-bottom: rem(15px);
			display: flex;
		}
	}
}