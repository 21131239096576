@import '~@scss/functions';

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;

	@media screen and (min-height: 1200px) {
		flex: 0;
	}

	.content {
		flex: 2;
	}
}

.controls {
	display: flex;
	justify-content: flex-end;
}

.mainImg {
	width: rem(382px);
	height: rem(370px);
}