@import './variables';
@import './functions';

body {
	background: linear-gradient(180deg, #f4faff 0%, #ffffff 800px, #fefefe 801px, #fefefe 100%) !important;
	font-size: rem(16px);
}

.noTextSelection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.rmDefaultTextareaStyles {
	border: none;
	overflow: auto;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}