@import '~@scss/functions';

.copyBtn {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: 0 auto;
	width: rem(27px);
	height: rem(27px);
	transition: all 0.4s ease-in;

	z-index: 10;
	>svg {
		z-index: 11;
		background-color: white;
		border-radius: 50%;
	}

	&:before {
		content: '';
		border-radius: 50%;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
	}
	&_focused {
		outline: 0;
		&:before {
			transform: scale(1, 1);
			animation: click_effect 0.8s ease-out;
		}
	}
}
@keyframes click_effect {
	50% {
		transform: scale(1.5, 1.5);
		opacity: 0;
		background-color: #0C5CE5;
	}
	99% {
		transform: scale(0.001, 0.001);
		opacity: 0;
		background-color: #0C5CE5;
	}
	100% {
		transform: scale(0.001, 0.001);
		opacity: 1;
		background-color: #0C5CE5;
	}
}