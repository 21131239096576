@import '~@scss/functions';

.gridWrapper {
	flex-direction: column !important;
	align-content: flex-start;
	flex: 1;
	width: rem(963px);

	@media screen and (min-height: rem(1200px)) {
		flex: 0;
	}

	.contentRow {
		flex: 1;
		flex-direction: column !important;
	}
	&__errorMessage {
		position: absolute;
		right: rem(20px);
		bottom: rem(100px);
	}
}

.resourcePreview {
	display: flex;
	justify-content: space-around;

	&_col {
		display: flex;
		flex-direction: column;
	}

	&_title,&_value {
		text-transform: uppercase;
		text-align: center;
	}

	&_title {
		line-height: rem(22px);
		font-weight: 400;
		font-size: rem(16px);
		color: #545a76;
		letter-spacing: rem(1px);
	}

	&_value {
		font-weight: 600;
		font-size: rem(32px);
		line-height: rem(40px);
		color: #000;
	}
}

.nodeAmountCircle {
	display: flex;
	color: #fff;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	width: rem(28px);
	height: rem(28px);
	background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
	border-radius: 50%;
}

.regionsPreviewGrid {
	display: flex;
	width: calc(100% + 30px);
	flex-wrap: wrap;
	flex: 1;
	margin: rem(-10px) rem(-15px);
	overflow-x: hidden;
	align-content: flex-start;

	&_item {
		flex-basis: 25%;
		height: auto;
		padding: rem(10px) rem(15px);
	}
}

.priceBarGroup {
	margin-bottom: rem(60px);
	.priceBarGroup_bar {
		background-color: #f8fcfe;
		.titleText,.priceText {
			font-weight: 600;
		}

		.titleText {
			color: #000;
		}

		.priceText {
			color: #0c5ce5;
		}
	}
}

.controlsRow {
	flex: 1;
}

.controls {
	display: flex;
	flex: 1;
	justify-content: space-between;
}

.cardFooterInfo {
	justify-content: center;
	text-align: center;

	color: #000000;
	letter-spacing: rem(0.16px);
	line-height: rem(22px);
}