@import '~@scss/functions';

.billingInformationForm {
	&__selectWrapper {
		width: rem(367px);
		height: rem(46px);
	}

	&__select {
		width: 100%;
		height: 100%;
		padding-right: rem(8px);
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
		line-height: rem(22px);
	}

	&__selectOptions {
		top: rem(45px) !important;
		width: 100%;
		max-height: rem(300px);
		overflow: auto;

		>div {
			height: rem(300px);
		}

		.selectWithSearch__select__options_top {
			top: rem(-45px) !important;
		}
	}
}