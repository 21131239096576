
@import '~@scss/functions';

.navSubMenuWrap {
	position: absolute;
	z-index: 1000;
	top: rem(35px);
	right: 0;
	background: transparent;
	height: auto;
	width: auto;

	.navSubMenu {
		border-radius: rem(6px);
		border: rem(1px) solid #d3e6fb;
		color: #000;
		background-color: #fff;
		padding: rem(7px);

		&__item {
			min-width: rem(158px);
			height: rem(44px);
			display: flex;
			align-items: center;
			color: #000000;
			font-weight: 500;
			font-size: rem(16px);
			letter-spacing: rem(0.16px);
			padding: rem(8px);

			&:hover {
				border-radius: rem(6px);
				font-weight: 700;
				background-color: #e4eefe;
			}

			&__iconWrap {
				background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
				width: rem(28px);
				height: rem(28px);
				border-radius: 50%;
				margin-right: rem(12px);
				display: flex;
				align-items: center;
				justify-content: center;
				background-size: rem(28px) rem(28px);

				&_danger {
					background: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
				}

				&__icon {
					background-size: auto;
					background-position: center;
					background-repeat: no-repeat;
					width: rem(28px);
					height: rem(28px);
				}
			}

			&_disabled {
				opacity: 0.2;
			}
		}
	}

	:global(.ui.pointing.label::before), :global(.ui[class*="pointing above"].label::before) {
		right: rem(23px);
		left: auto;
	}
}