@import '~@scss/functions';

.portMappingFieldList {
	display: flex;
	flex-direction: column;
	min-width: 0;

	.inputsRow {
		min-width: 0;
		display: flex;
		align-items: flex-start;
	}

	.inputs {
		display: flex;
		flex: 1;
		min-width: 0;

		.section {
			display: flex;
			padding-right: rem(10px);
			flex: 1;

			&_inputCol {
				width: 100%;
				display: flex;
				flex-direction: column;
				flex: 3;
				min-width: 0;

				.input {
					flex: 1;
					display: flex;
					height: rem(46px);
					width: rem(135px);

					& input::placeholder {
						color: #545a76;
					}
				}

			}

			.label {
				flex: 1;
				margin-right: rem(20px);
			}
		}
	}

	.trashBtn {
		&.hidden {
			visibility: hidden;
		}
	}
}

.selectWrapper {
	align-items: normal;
}

.select {
	height: rem(46px);
	width: rem(105px);
	&__label {
		flex: 1;
		margin-right: rem(20px);
		cursor: default;
		line-height: rem(46px);
		letter-spacing: normal;
	}

	>input {
		padding-left: rem(11px);
	}
	>img {
		width: rem(12px);
		height: rem(7px);
		right: rem(15px);
	}
}

.selectOptions {
	top: rem(46px) !important;
}

.errorText {
	width: rem(135px);
}
