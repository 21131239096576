@import '~@scss/functions';

$black: #000;
$labelTextWidth: rem(200px);

.regInputWrap {
	display: flex;
	margin-bottom: rem(40px);
	align-items: center;
	position: relative;
	width: rem(566px);

	&__input {
		color: #000000;
		font-family: Raleway, sans-serif;
		background-color: #fff;
		border: rem(1px) solid #b9cee4;
		border-radius: rem(6px);
		width: rem(366px);
		padding-left: rem(16px);
		padding-top: rem(12px);
		padding-bottom: rem(12px);
		outline: none;
		transition: all 240ms ease;
		box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05);

		&::placeholder {
			color: rgba(0, 0, 0, 0.4);
		}

		&:focus {
			box-shadow: 0 rem(3px) rem(6px) rgba(12, 92, 229, 0.2);
			border: rem(1px) solid #0C5CE5;
			outline: none;
		}

		&_error {
			box-shadow: 0 rem(3px) rem(6px) rgba(255, 83, 42, 0.2);
			border-radius: rem(6px);
			border: rem(1px) solid #ff3232;
			background-color: #ffffff;
			&:focus {
				border: rem(1px) solid #ff3232;
				box-shadow: 0 rem(3px) rem(6px) rgba(255, 83, 42, 0.2);
			}
		}

		&_disabled {
			background: #fafdff;
			color: rgba(0, 0, 0, 0.4);
			border: rem(1px) solid #b9cee4;
			cursor: not-allowed;
		}
	}

	&__text {
		display: flex;
		align-items: center;
		width: $labelTextWidth;
		color: $black;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__skeleton {
		width: rem(128px);
		position: absolute;
	}

	&__errorLogo {
		position: absolute;
		right: rem(7px);
		width: rem(32px);
		height: rem(32px);
	}

	&__errorLabel {
		position: absolute;
		z-index: 1000;
		left: $labelTextWidth;
		top: rem(50px);
		color: #ff3232;
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
		line-height: rem(22px);

		&_withDetail {
			top: rem(70px);
		}
	}

	&_active {
		>input {
			color: #000000;
		}
	}
}