@import '~@scss/functions';

.wrapper {
	flex-direction: column !important;
	flex: 1;
}

.controls {
	display: flex;
	justify-content: space-between;

	&_singleBtn {
		justify-content: flex-end;
	}
	margin-left: rem(15px);
	margin-right: rem(15px);
}

.resourcesStep {
	&__priceBarGroup {
		display: flex;
		justify-content: center;
		&_bar {
			width: auto !important;
			height: rem(28px) !important;
			border: 0 !important;
			border-radius: 0 !important;
			background-color: none !important;
			padding: 0 !important;
			&:nth-child(1) {
				margin-right: rem(190px);
				width: rem(269px) !important;
			}
			&:nth-child(2) {
				>div:nth-child(2) {
					flex: none;
					width: auto;
					margin-right: rem(40px);
				}
			}

			>div:nth-child(2) {
				flex: 1;
				white-space: nowrap;
			}

			>div:nth-child(3) {
				>span {
					white-space: nowrap;
				}
			}
		}
	}
}

.resourcesRow {
	display: flex;
	margin-bottom: rem(175px);
	position: relative;
}

.notAvailableBanner {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: rem(66px);
	left: rem(378px);
	width: rem(405px);
	height: rem(64px);
	border-radius: rem(6px);
	border: rem(1px) solid #d4e2f1;
	background-color: #ffffff;
	z-index: 5;
	>span {
		margin-right: rem(16px);
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
	}
	>button {
		width: rem(192px) !important;
		height: rem(32px) !important;
		color: #104378 !important;
		font-size: rem(14px) !important;
		font-weight: 600 !important;
		letter-spacing: rem(0.14px) !important;
	}
}