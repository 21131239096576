@import '~@scss/functions';

.emptyRegistries {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__logoWrap {
		width: rem(346px);
		height: rem(281px);
		padding: 0 rem(79px) rem(33px) rem(39px);

		&__logo {
			width: rem(228px);
			height: rem(248px);
		}
	}

	&__header {
		color: #050d29;
		font-size: rem(32px);
		font-weight: 700;
		line-height: rem(40px);
		margin-bottom: rem(10px);
		width: rem(346px);
	}

	&__subHeader {
		color: #000000;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
		margin-bottom: rem(40px);
	}

	&__ctrl {
		width: rem(178px);
		height: rem(40px);
	}
}