@import '~@scss/functions';

.centeredLayout {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding-top: rem(40px);

	&__controlsRow {
		flex: 1;
		width: 100%;
		max-width: rem(963px);
		margin: rem(32px) 0;
		max-height: rem(40px);
	}

	.header {
		flex: 1;
		text-align: center;
		max-height: rem(60px);
	}

	.content {
		flex: 2 1;
		width: 100%;
		max-width: rem(963px);
		max-height: rem(370px);
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		box-sizing: border-box;
		overflow: hidden;
	}
}

@media screen and (min-height: rem(768px)) {
	.centeredLayout {
		.content {
			max-height: 100%;
		}
	}
}

@media screen and (min-height: rem(1200px)) {
	.centeredLayout {
		.content {
			flex: 1;
			height: rem(517px);
			max-height: rem(517px);
		}

		&__controlsRow {
			flex: 2;
		}
	}
}
