@import '~@scss/functions';

$loadingBlockHeight: rem(3px);
$deployBtnHeight: rem(32px);

.appRequestButtonWrapper {
	display: flex;
	align-items: center;

	&__clearBtn {
		margin-right: rem(24px);
		color: #104378;
		font-size: rem(16px);
		font-weight: 700;
		letter-spacing: rem(0.16px);
		cursor: pointer;
	}
}

.appRequestButton {
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: rem(6px);
	background: #fff;
	color: #104378;
	border: rem(1px) solid #d3e6fb;
	overflow: hidden;

	&:not(.appRequestButton_disable):hover,
	&_active:not(.appRequestButton_disable) {
		background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
		color: #fff;
		border: none;
	}

	&_disabled {
		opacity: 0.8;
		pointer-events: none;
		cursor: initial;
	}

	&_dirty {
		background-image: linear-gradient(to top, #f89728 0%, #f8bc23 100%) !important;
		color: #fff !important;

		&::before {
			background: none !important;
		}
		:hover {
			background-image: linear-gradient(to top, #F8AB53 0%, #F8D372 100%) !important;
		}
		:active {
			background: linear-gradient(to top, #f87e27 0%, #f8a623 100%);
		}
	}

	&__textNIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		height: 100%;

		&__textBlock {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: rem(105px);

			&__btnText {
				position: absolute;
				top: -($deployBtnHeight + $loadingBlockHeight);
				height: $deployBtnHeight;
				display: flex;
				justify-content: center;
				align-items: center;
				font-family: Raleway, sans-serif;
				font-size: rem(16px);
				font-weight: 600;
				letter-spacing: rem(0.16px);
				white-space: nowrap;

				&_visible {
					top: 0;
					visibility: visible;
					transition: top .3s ease-in-out;
				}
			}
		}

		&__btnIconBlock {
			display: flex;
			justify-content: flex-start;

			&_visible {
				width: rem(25px);
				height: 100%;
			}

			&__btnIcon {
				display: flex;
				height: 100%;

				&_loading {
					animation: rotating 1s linear infinite;
					>svg {
						width: rem(16px);
						height: rem(16px);
					}
				}
			}
		}
	}

	&__loadingBlock {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: rem(3px);

		&__loadingProgressBar {
			display: flex;
			height: 100%;
			width: 0;
			background: #00A7FF;
			transition: width .4s ease-in-out;
		}
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}