@import '../../variables';
@import '~@scss/functions';

.head {
	display: flex;
	width: $layout_head_width;
	min-height: rem(88px); //160px
	justify-content: space-between;
	align-items: center;
	margin: auto;

	&__titleSection {
		width: 100%;
	}
}
