@import '~@scss/functions';

.layout {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
	min-width: 0;
	padding: rem(5px) 0;
}

.item {
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	min-width: 0;
}

.title {
	max-width: 55%;
	color: #000000;
	font-size: rem(16px);
	font-weight: 400;
	letter-spacing: rem(0.16px);
}

.value {
	max-width: 45%;
}
