@import '~@scss/functions';

.inputHintWrapper {
	display: flex;
	flex-direction: column;

	.hint {
		font-size: rem(14px);
		line-height: rem(22px);
		font-weight: 400;
		color: #000;
		padding-left: rem(4px);
	}
}

.textareaErrorLabel {
	width: 100%;
}

.textareaInput {
	border: rem(1px) solid #b9cee4;
	background-color: #ffffff;
	width: rem(367px);
	height: rem(92px);
	resize: none;
	box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05);
	font-family: Raleway, sans-serif;

	&:active, &:focus {
		border: rem(1px) solid #0c5ce5;
		box-shadow: 0 rem(3px) rem(6px) rgba(12, 92, 229, 0.2);
	}

	&::placeholder {
		color: rgba(0, 0, 0, 0.4);
	}

	&_error {
		border: rem(1px) solid #ff3232;
		&:focus {
			border: rem(1px) solid #ff3232;
			box-shadow: 0 rem(3px) rem(6px) rgba(255, 83, 42, 0.2);
		}
	}
}

.nameInput {
	>input {
		width: rem(194px);
	}
}