@import '~@scss/functions';

.inputWithInnerButton {
	position: relative;
	width: 100%;
	display: flex;


	&_input {
		width: 100%;

		&>input {
			width: 100%;
			padding-right: 35% !important;
			color: #000;
			background-color: #fff;
			border: rem(1px) solid #b9cee4 !important;
			box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05) !important;

			&::placeholder {
				color: rgba(0, 0, 0, 0.4) !important;
			}
		}

		&__withError {
			box-sizing: border-box;
			input {
				display: block;
				padding-right: rem(44px) !important;
				background-color: #fff !important;
				box-sizing: border-box !important;
			}
		}
	}

	&__btn {
		position: absolute !important;
		right: 0 !important;
		top: 50% !important;
		max-height: calc(100% - 10px) !important;
		max-width: 35% !important;
		transform: translateY(-50%) !important;
		margin-right: rem(5px) !important;
	}

	.errorHint {
		width: rem(32px);
		height: rem(32px);
		position: absolute;
		right: rem(6px);
		align-self: center;
	}
}