@import '~@scss/functions';

$radius: rem(15px);
$border: rem(1px) solid #d4e2f1;

.appTable {
	width: 100%;

	&.roundBorders {
		.appTable__body {
			.appTable__row {
				&:last-child {
					.appTable__col {

						border-bottom: rem(1px) solid #d4e2f1;

						&:first-child {
							border-bottom-left-radius: $radius;
						}

						&:last-child {
							border-bottom-right-radius: $radius;
						}
					}
				}
			}
		}
	}

	&__col {
		border: $border;
		height: rem(76px);

		&__content {
			padding-left: rem(20px);
			padding-right: rem(20px);
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
			>span {
				margin-right: rem(9px);
			}
		}
	}

	&__row {
		&:not(.appTable__footer) {
			.appTable__col {
				border-bottom: none;
			}
		}

		.appTable__col {
			&:first-child {
				border-right: none;
			}

			&:last-child {
				border-left: none;
			}

			&:not(:first-child):not(:last-child) {
				border-left: none;
				border-right: none;
			}
		}
	}

	&__head {
		.appTable__col {
			font-size: rem(16px);
			font-weight: 600;
			letter-spacing: rem(0.16px);
			color: #000;
			border: none;
		}
	}

	&__body {
		.appTable__row {
			background-color: #fff;
			transition: all 240ms ease;

			&:hover {
				background-color: #f8fcfe;
			}

			&:first-child {
				.appTable__col {

					&:first-child {
						border-top-left-radius: $radius;
					}

					&:last-child {
						border-top-right-radius: $radius;
					}
				}
			}

			&:only-child {
				.appTable__col {
					&:first-child {
						border-left: rem(1px) solid #d4e2f1;
					}

					&:last-child {
						border-right: rem(1px) solid #d4e2f1;
					}
				}
			}
		}

	}

	&__footer {
		background-color: #f8fcfe;

		.appTable__col {
			&:first-child {
				border-bottom-left-radius: $radius;
			}

			&:last-child {
				border-bottom-right-radius: $radius;
			}
		}
	}
}