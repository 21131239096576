@import '~@scss/functions';

$gradientTwo: linear-gradient(180deg, #f4faff 0%, #ffffff 800px, #fefefe 801px, #fefefe 100%);

.cluster {
	&__subNav {
		margin-right: auto;
		margin-left: auto;

		button {
			font-size: rem(14px) !important;
			font-weight: 600 !important;
			letter-spacing: rem(0.14px);
		}
	}

	.subnavContent {
		position: relative;
		display: flex;
		justify-content: flex-end;
	}

	.clusterNavBtn {
		height: rem(32px);
		margin-right: 0;
	}

	.notFoundLayout {
		padding-top: rem(64px);
	}
}

.skeleton {
	min-width: rem(165px);
	height: rem(8px)
}