
@import '~@scss/functions';

.errorMessage {
	width: rem(367px);
	border-radius: rem(6px);
	border: rem(1px) solid #ff3232;
	background-color: #fff3f3;
	color: #000000;
	display: flex;
	padding: rem(16px) rem(15px) rem(15px) rem(16px);
	margin-bottom: auto;
	bottom: rem(30px);
	position: relative;

	&_absolute{
		position: absolute;
		bottom: 5%;
		right: rem(5px);
	}

	&__close {
		display: flex;
		justify-content: center;
		margin-right: rem(16px);

	}
	&__closeIcon {
		cursor: pointer;
		width: rem(32px);
		height: rem(32px);
	}

	&__content {
		&__header {
			font-size: rem(16px);
			font-weight: 600;
			letter-spacing: rem(0.16px);
			margin-bottom: rem(5px);
		}
		&__msg {
			font-size: rem(14px);
			font-weight: 400;
			letter-spacing: rem(0.14px);
			line-height: rem(17px);
		}
	}
}
