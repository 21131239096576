@import '~@scss/functions';

.teamMember {
	position: relative;
	&__userData {
		&__avatar {
			width: rem(42px);
			height: rem(42px);
			margin-right: rem(20px);
		}

		&__nameEmail {
			color: #000000;
			font-weight: 400;

			>div:nth-child(1) {
				font-size: rem(16px);
				letter-spacing: rem(0.16px);
			}
			>div:nth-child(2) {
				opacity: 0.5;
				font-size: rem(14px);
				letter-spacing: rem(0.14px);
			}
		}
	}

	&__role {
		text-transform: capitalize;
	}

	&__status {
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(88px);
		height: rem(32px);
		border-radius: rem(6px);
		font-size: rem(14px);
		font-weight: 600;
		letter-spacing: rem(0.14px);
		text-transform: capitalize;

		&__accepted {
			background-color: rgba(18, 212, 173, 0.2);
			color: #0d886f;
		}

		&__pending {
			background-color: rgba(255, 157, 43, 0.2);
			color: #d58221;
		}
	}

	&__dots {
		position: relative;

		&__icon {
			cursor: pointer;
			height: rem(30px);
		}
	}

	&__contextMenuWrapper {
		position: absolute;
		top: rem(24px);
		right: rem(-8px);
		z-index: 100;
		padding-top: rem(10px);
	}

	&__contextMenu {
		background: #ffffff;
		border-radius: rem(6px);
		border: rem(1px) solid #d3e6fb;
		padding: rem(8px);

		&::before {
			left: auto;
			right: rem(13px);
			position: absolute;
			content: '';
			transform: rotate(45deg);
			width: rem(10px);
			height: rem(10px);
			transition: background 0.1s ease;
			top: rem(5px);
			border-color: #d3e6fb;
			border-style: solid;
			border-width: rem(1px) 0 0 rem(1px);
			z-index: 1001;
			background: #ffffff;
		}


		&__item  {
			height: rem(42px);
			width: rem(135px);
			display: flex;
			justify-content: left;
			align-items: center;
			color: #000000;
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			padding-left: rem(5px);
			cursor: pointer;

			&_danger, &__edit {
				>div {
					width: rem(26px);
					height: rem(26px);
					border-radius: 50%;
					margin-right: rem(10px);
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			&_danger {
				color: #ff3232;
				>div {
					background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
				}
			}

			&__edit {
				>div {
					background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
				}
			}

			>img {
				margin-left: rem(6px);
				margin-right: rem(10px);
			}

			&:hover {
				border-radius: rem(6px);
				background-color: #e4eefe;
				font-weight: 600;
			}
		}
	}

	&__invited {
		color: #000;
	}
}