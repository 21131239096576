@import '~@scss/functions';

.table {
	position: relative;

	&__loadingRow {
		> div {
			height: rem(228px);
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

		&__icon {
			height: rem(66px);
		}

		&__label {
			letter-spacing: rem(0.16px);
			font-weight: 600;
			padding-top: rem(16px);
			margin: 0 auto;
		}
	}
}

.tableCol {
	cursor: default;
}

.noPadding > div {
	padding: 0 !important;
}

.cellContent {
	display: flex;
	align-items: center;
	width: 100%;

	.flagIcon {
		margin-right: rem(20px);
	}

	.code {
		text-transform: uppercase;
		letter-spacing: rem(0.16px);
	}
}

.footerBtnWithIcon {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.decoratedText {
	display: flex;
	align-items: center;
	font-size: rem(14px);
}

.angleRightDark {
	margin-left: rem(5px);
}

.skeletonLine {
	max-width: rem(88px);
	width: 100%;
	height: rem(8px);
	border-radius: rem(2px);
	background-color: #104378;
	opacity: 0.1;
}

.btn {
	height: rem(32px);
}

.emptyListText {
	font-size: rem(14px);
	line-height: rem(20px);
	margin-bottom: 0;
	opacity: 0.8;
	text-align: center;
	font-weight: 600;
	width: 100%;
	color: #000;
}

.lastRow {
	> td {
		border-bottom: rem(1px) solid rgb(212, 226, 241) !important;
	}
}
