@import '~@scss/functions';

.iconWithTooltip {
	position: relative;
	display: flex;
	align-items: center;
	margin-left: rem(8px);

	&__iconWrap {
		cursor: pointer;
		width: rem(16px);
		height: rem(16px);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
	}

	&__icon {
		pointer-events: none;
	}

	&__label {
		z-index: 1000;
		position: absolute;
		color: #FFFFFF;
		background: rgba(0,0,0,0.75);
		border-radius: rem(6px);
		width: max-content;
		max-width: rem(500px);
		font-weight: 400;
		font-size: rem(14px);
		letter-spacing: rem(0.14px);
		line-height: normal;
		text-align: left;
		left: rem(-15px);
		bottom: rem(30px);
		padding: rem(11px) rem(15px);

		&::before {
			right: auto;
			left: rem(13px);
			position: absolute;
			content: '';
			width: rem(10px);
			height: rem(6px);
			transition: background 0.1s ease;
			bottom: rem(-9px);
			border-left: solid transparent rem(10px);
			border-right: solid transparent rem(10px);
			border-top: solid rgba(0,0,0,0.75) rem(10px);
			z-index: 1001;
		}
	}
}
