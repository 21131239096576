@import '~@scss/functions';

.labelWithTooltip {
	display: flex;
	align-items: center;

	.icon {
		width: rem(16px);
		height: rem(16px);
		margin-left: rem(6px);
	}
}