@import '~@scss/functions';

.billingInfoBlockLayout {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: rem(30px) 0;
		border-bottom: rem(1px) solid #d4e2f1;
	}

	&__headerTitle {
		color: #000;
		font-size: rem(24px);
		font-weight: 600;
		letter-spacing: rem(0.24px);
	}

	&__content {
		padding: rem(40px) 0 rem(30px);
	}
}