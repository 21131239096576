@import '~@scss/functions';

.billingBandwidthChart {
	display: flex;
	flex-direction: column;
	border-radius: rem(6px);
	border: rem(1px) solid #d4e2f1;
	background-color: #ffffff;
	height: rem(231px);

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: rem(60px);
		padding: 0 rem(16px);
	}

	&__headerTitle {
		color: #000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
		display: flex;
	}

	&__clusterSelect {
		&>input, &>div:nth-child(1) {
			padding-left: rem(10px);
			padding-right: 0;
			width: rem(142px);
			height: rem(32px);
			color: #104378;
			font-size: rem(14px);
			font-weight: 600;
			letter-spacing: rem(0.14px);
		}

		>img {
			width: rem(12px);
			height: rem(8px);
			right: rem(9px);
		}

		&>span {
			&>svg {
				right: rem(8px);
			}
		}
	}

	&__clusterSelectOptions {
		right: rem(-6px);

		&::before {
			position: absolute;
			top: rem(-6px);
			right: rem(15px);
			content: "";
			display: flex;
			width: 0;
			height: 0;
			border-left: rem(6px) solid transparent;
			border-right: rem(6px) solid transparent;
			border-bottom: rem(6px) solid #d4e2f1;
		}

		&::after {
			position: absolute;
			top: rem(-4px);
			right: rem(15px);
			content: "";
			display: flex;
			width: 0;
			height: 0;
			border-left: rem(6px) solid transparent;
			border-right: rem(6px) solid transparent;
			border-bottom: rem(6px) solid #fff;
		}

		&__option {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			&__flag {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: rem(8px);
				overflow: hidden;

				>i {
					width: rem(21px);
					height: rem(15px);
				}
			}

			&__clusterName {
				white-space: nowrap;
				margin-right: rem(8px);
				flex-grow: 1;
			}

			&__info {
				display: flex;
				align-items: center;
				border-radius: rem(4px);
				border: rem(1px) solid #dae6f2;
				background-color: #ffffff;

				&__value {
					width: rem(60px);
					text-align: right;
				}

				&__value, &__units {
					padding: rem(5px);
					color: #104378;
					font-size: rem(16px);
					font-weight: 500;
					letter-spacing: rem(0.16px);
					line-height: rem(22px);

					&:last-child {
						background-color: #f7fbff;
						border-left: rem(1px) solid #dae6f2;
					}
				}
			}
		}
	}

	&__clusterSelectOptText {
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
		line-height: rem(22px);
	}

	&__content {
		display: flex;
		flex: 2 1 rem(60px);
		padding: rem(20px);
		border-top: rem(1px) solid #d4e2f1;
	}

	&__totalInfo {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 30%;
	}

	&__totalInfoLabel {
		color: #545a76;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(1.5px);
		line-height: rem(22px);
		text-transform: uppercase;
	}

	&__totalInfoValue {
		color: #050d29;
		font-size: rem(28px);
		font-weight: 700;
		line-height: rem(40px);
		text-transform: uppercase;
	}

	&__appSpinner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		&__loadingRow {
			> div {
				height: rem(228px);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}

			&__icon {
				height: rem(66px);
			}

			&__label {
				letter-spacing: rem(0.16px);
				font-weight: 600;
				padding-top: rem(16px);
				margin: 0 auto;
			}
		}
	}
}

.metricLayout {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	width: 70%;
	border-radius: rem(6px);
	border: rem(1px) solid #d4e2f1;
	background-color:  #fafdff;

	&_borderless {
		border: none;
	}

	.metric {
		width: 100%;
		position: relative;
		padding: rem(12px) 0 0 rem(12px);

		.metricWrapper {
			position: relative;
			height: rem(114px);

		}

		&__title {
			letter-spacing: rem(0.16px);
			font-weight: 700;
			color: #000;
			margin-bottom: rem(26px);
			display: flex;
		}
	}
}