@import '~@scss/functions';

.content {
	display: flex;
	flex-wrap: nowrap;

	.flagIcon {
		width: rem(28px) !important;
		height: rem(20px) !important;
		margin-right: rem(16px);
		display: block;
	}

	.label {
		font-weight: 600;
		font-size: rem(16px);
		color: #000;
		text-transform: uppercase;
	}
}

.popup {
	width: rem(111px);
	height: rem(47px);
	&:hover {
		z-index: 99999999999;
	}
}

.openedAndNotActive {
	opacity: 0.5;
}
