@import '~@scss/functions';

.stripeCard {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__cvcExpiryWrap {
		display: flex;
		justify-content: flex-end;
		width: rem(566px);
		margin-bottom: rem(24px);
	}

	&__numberWrap {
		position: relative;
		width: rem(566px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: rem(30px);

		&__visaIcon {
			position: absolute;
			right: rem(10px);
			>svg {
				width: rem(38px);
				height: rem(26px);
			}
		}

		>span {
			color: #000000;
			font-size: rem(16px);
			font-weight: 600;
			letter-spacing: rem(0.16px);
		}
	}

	&__number, &__cvc, &__expiry {
		line-height: rem(22px);
		height: rem(46px);
		background-color: white;
		border-radius: rem(6px);
		padding: rem(12px);
		padding-left: rem(15px);
		border: rem(1px) solid #b9cee4;
		box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05);

		&::placeholder {
			color: rgba(0, 0, 0, 0.4);
		}

		&_focus {
			box-shadow: 0 rem(3px) rem(6px) rgba(12, 92, 229, 0.2);
			border: rem(1px) solid #0c5ce5;
			border-radius: rem(6px) !important;
		}

		&_invalid {
			border-color: #fa755a;
		}
	}

	&__number {
		width: rem(367px);
	}
	&__cvc, &__expiry {
		width: rem(183px);
	}
	&__cvc {
		border-radius: 0 rem(6px) rem(6px) 0;
	}
	&__expiry {
		border-radius: rem(6px) 0 0 rem(6px);
	}
	&__name {
		>input {
			font-weight: 400 !important;
			font-size: rem(16px) !important;
			letter-spacing: rem(0.16px) !important;
		}
	}
}


