@import '~@scss/functions';

.editRole {
	width: rem(367px);
	height: rem(341px);
	border-radius: rem(16px);
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__icon {
		position: absolute;
		top: rem(-43px);
		left: rem(141px);
		width: rem(86px);
		height: rem(86px);
	}

	&__close {
		position: absolute;
		right: 0;
		top: rem(-34px);
		cursor: pointer;
		width: rem(20px);
		height: rem(20px);

		.hidden {
			pointer-events: none;
			visibility: hidden;
		}
	}

	&__header {
		margin-top: rem(59px);
		margin-bottom: rem(7px);
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}
	&__subHeader {
		opacity: 0.5;
		color: #000000;
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
		margin-bottom: rem(20px);
	}

	&__form {
		display: flex;
		flex-direction: column;
		align-items: center;

		&__msg {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			width: rem(317px);
			min-height: rem(54px);
			border-radius: rem(6px);
			border: rem(1px) solid #d4e2f1;
			background-color: #f8fcfe;
			margin-top: rem(20px);
			margin-bottom: rem(35px);
			font-size: rem(14px);
			font-weight: 400;
			letter-spacing: rem(0.14px);
			padding: rem(8px);
			line-height: rem(18px);
		}


		&__wrapSelect {
			width: 100%;
			height: rem(46px);

			&__label {
				width: rem(107px);
				font-size: rem(16px);
			}

			&__select {
				display: flex;
				justify-content: space-between;
				height: rem(46px);
				width: rem(210px);
				padding-right: rem(10px);
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(0.16px);
				line-height: rem(22px);

				&__options {
					top: rem(46px) !important;
					width: rem(210px);
					&__option {
						width: rem(198px);
						height: rem(34px);
					}
				}
			}
		}

	}

	&__saveBtn {
		width: rem(105px);
		height: rem(40px);
		border-radius: rem(6px);
		position: absolute;
		bottom: rem(20px);
	}
}