@import '~@scss/functions';

.btnProgress {
	height: rem(44px);
	position: relative;

	.btnProgressText {
		justify-content: center;
		align-items: center;
		padding: 0 rem(40px);

		&__spinner {
			position: absolute;
			right: rem(6px);
			top: calc(50% - 10px);
		}

		>span {
			font-size: rem(16px);
			font-weight: 700;
			letter-spacing: rem(0.16px);
		}
	}

	.btnProgressBar {
		height: rem(5px);
		width: 100%;
		background-color: rgba(255,255,255, 0.2);
		border-bottom-left-radius: rem(6px);
		border-bottom-right-radius: rem(6px);
		position: absolute;
		bottom: 0;

		.progressBarStep {
			background: #00a6ff;
			height: rem(5px);
			width: 10%;

			&:nth-child(10) {
				border-bottom-right-radius: rem(6px);
			}

			&:first-child {
				border-bottom-left-radius: rem(6px);
			}
		}
	}
}