
@import '~@scss/functions';

.inviteInput {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: rem(35px);
	margin-bottom: rem(20px);
	position: relative;


	.container {
		&__tags {
			display: flex;
			position: relative;
			&_grow {
				flex-grow: 1;
			}
		}

		&__input {
			min-width: rem(80px);
			flex-grow: 1;
			position: relative;
			&_grow {
				flex-grow: 1;
			}
		}
	}

	:global(.react-tagsinput) {
		display: flex;
		align-items: center;
		width: rem(526px);
		height: rem(46px);
		border: rem(1px) solid #b9cee4;
		background-color: #ffffff;
		border-radius: rem(6px);
		overflow: hidden;
		padding-left: rem(16px);
		padding-right: rem(141px);
		box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05);

		>div {
			display: flex;
			align-items: center;
			overflow: hidden;
			width: rem(360px);
		}

		&--focused {
			border-color: #0C5CE5;
		}
		&-tag {
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: left;
			margin-right: rem(7px);
			padding-left: rem(11px);

			height: rem(32px);
			line-height: rem(32px);
			box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
			border-radius: rem(6px);
			border: rem(1px) solid #d3e6fb;
			background-color: #ffffff;
			color: #104378;
			font-size: rem(14px);
			font-weight: 600;
			letter-spacing: rem(0.14px);

			a {
				width: rem(12px);
				height: rem(12px);
				margin-left: rem(6px);
				margin-right: rem(10px);
				background: url("~@icons/icon-12-cross-dark-noraw.svg") no-repeat;
				background-size: contain;
			}
		}

		&-remove {
			cursor: pointer;
			font-weight: bold;
		}

		&-input {
			background: transparent;
			border: 0;
			color: #777;
			font-family: sans-serif;
			font-size: rem(13px);
			font-weight: 400;
			outline: none;
			padding: rem(5px);
			width: 100%;
		}
	}

	&_error {
		:global(.react-tagsinput) {
			border: rem(1px) solid #ff3232;

			&-tag {
				opacity: 0.4;
			}
		}
	}

	&__errorSign {
		position: absolute;
		right: rem(12px);
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(74px);
		height: rem(32px);
		border-radius: rem(6px);
		background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
		color: #ffffff;
		font-size: rem(14px);
		font-weight: 600;
		letter-spacing: rem(0.14px);

		> span:nth-child(1) {
			margin-right: rem(6px);
		}
		img {
			margin-right: rem(6px);
		}
	}
}

.inviteMemberBtn {
	position: absolute !important;
	right: rem(12px) !important;
	width: rem(132px) !important;
	height: rem(32px);
	>span {
		font-size: rem(14px);
		font-weight: 600;
		letter-spacing: rem(0.14px);
	}
}
