@import '~@scss/functions';

.stepLayoutWithLogo {
	display: flex;
	flex-direction: column;
	max-width: rem(1200px);
	width: 100%;
	height: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	padding: rem(80px) rem(16px) 0 rem(16px);

	&__contentWrapper {
		display: flex;
		flex: 2 1;
	}

	&_imageBlock {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		width: 43.7%;
		text-align: center;
	}

	&__contentBlock {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
		height: 100%;
		width: 57.3%;
	}

	&__contentHeader {
		width: 100%;
	}

	&__content {
		flex: 2 1;
		width: 100%;
	}

	&__controlsRow {
		max-width: rem(963px);
		width: 100%;
		padding: rem(32px) 0;
		margin: 0 auto;
	}
}


@media screen and (min-height: rem(1200px)) {
	.stepLayoutWithLogo {
		&__contentRow {
			flex: 1;
			height: rem(575px);
		}

		&__controlsRow {
			margin-top: rem(32px);
			flex: 2;
		}
	}
}
