
.contextMenu {
	z-index: 100;

	&__item {
		cursor: pointer;
	}
}

.contextMenuWrapper {
	position: absolute;
}