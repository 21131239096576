@import '~@scss/functions';

.invoiceItem {
	&__amount {
		color: #0c5ce5;
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__status {
		padding-left: rem(20px);
		padding-right: rem(20px);
		>div {
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(88px);
			height: rem(32px);
			border-radius: rem(6px);
			font-size: rem(14px);
			font-weight: 600;
			letter-spacing: rem(0.14px);
		}

		&_pending {
			>div {
				color: #d58221;
				background-color: rgba(255, 157, 43, 0.2);
			}
		}
		&_paid {
			>div {
				color: #0d886f;
				background-color: rgba(18, 212, 173, 0.2);
			}
		}
	}
	&__download {
		cursor: pointer;

		>div {
			background-image: none;
		}

		&__label {
			width: rem(130px);
			min-width: rem(130px);
			left: rem(-15px);
			font-size: rem(14px);
			font-weight: 400;
			letter-spacing: rem(0.14px);
		}
	}
}