@import '~@scss/functions';

.httpsInfo {
	display: flex;
	flex-direction: column;
	min-width: 0;

	.inputsRow {
		min-width: 0;
		display: flex;
		align-items: flex-start;
	}

	.inputs {
		display: flex;
		flex: 1;
		min-width: 0;

		.section {
			display: flex;
			padding-right: rem(10px);
			flex: 1;

			&_inputCol {
				width: 100%;
				display: flex;
				flex-direction: column;
				flex: 3;
				min-width: 0;

				.input {
					flex: 1;
					display: flex;
					height: rem(46px);
					width: rem(135px);
					& input {
						color: #000000 !important;
						padding-left: rem(11px) !important;
					}
				}
			}

			.label {
				flex: 1;
				margin-right: rem(20px);
				width: rem(150px);
			}
		}
	}
}