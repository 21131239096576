@import '~@scss/functions';

.invoicesList {
	&__contentWrapper {
		width: rem(1200px);
		margin-left: auto;
		margin-right: auto;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: rem(57px);
		margin-bottom: rem(60px);
	}

	&__headerTitle {
		color: #050d29;
		font-size: rem(32px);
		font-weight: 700;
		line-height: rem(40px);
	}

	&__contentBlock {
		display: flex;
		justify-content: center;

		&__table {
			width: rem(1200px);

			tbody {
				tr {
					cursor: default !important;
				}
				tr:nth-last-child(1) {
					td {
						border-bottom: rem(1px) solid #d4e2f1 !important;
					}
					>td:nth-child(1) {
						border-bottom-left-radius: rem(15px);
					}
					>td:nth-last-child(1) {
						border-bottom-right-radius: rem(15px);
					}
				}
			}
		}
	}

	&__error {
		bottom: rem(100px);
		right: rem(20px);
	}
}

.noInvoices {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__mainImg {
		width: rem(208px);
		height: rem(262px);
		margin-bottom: rem(32px);
		background: url("~@images/no.invoices.png") no-repeat;
	}

	&__title, &__subTitle {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__title {
		color: #050d29;
		font-size: rem(32px);
		font-weight: 700;
		line-height: rem(40px);
		margin-bottom: rem(10px);
	}

	&__subTitle {
		color: #000000;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
	}
}


.loadingRow {
	> div {
		height: rem(228px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	&__icon {
		height: rem(66px);
	}

	&__label {
		letter-spacing: rem(0.16px);
		font-weight: 600;
		padding-top: rem(16px);
		margin: 0 auto;
	}
}