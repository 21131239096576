@import '~@scss/functions';

.appCardSectionContainer {
	display: flex;
	flex-wrap: wrap;
	height: 100%;

	.appCardSection {
		display: flex;
		align-items: center;
		width: 100%;
		padding: rem(20px);
		flex: 1;

		border-right: rem(1px) solid #d4e2f1;

		&:last-child {
			border: none;
		}
	}
}