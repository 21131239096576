@import '~@scss/functions';

.btnWithIcon {
	display: inline-block;
	box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
	border-radius: rem(6px) !important;
	border: rem(1px) solid #d3e6fb;
	background-color: #ffffff;
	color: #104378;
	font-size: rem(14px) !important;
	font-weight: 600 !important;
	letter-spacing: rem(0.14px) !important;
	height: rem(32px) !important;
	width: rem(95px) !important;

	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		&__icon {
			width: rem(14px);
			height: rem(14px);
			&_left {
				margin-right: rem(9px);
			}
			&_right {
				margin-left: rem(9px);
			}
		}
	}
}