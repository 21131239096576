@import '~@scss/functions';


$duration: 2s;
$base-color: #e4eefe;
$shine-color: rgba(255,255,255, 0.6);

.skeleton {
	max-width: 100%;
	height: rem(6px);
	background-color: #e4eefe;
	background-image: linear-gradient(90deg, $base-color 0, $shine-color rem(40px), $base-color rem(80px));
	background-size: 450%;
	animation: shine-lines $duration infinite linear;
}

@keyframes shine-lines {
	0% {
		background-position: 80%;
	}

	100% {
		background-position: -100%;
	}
}