@import '~@scss/functions';

.appInfoBlock {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: rem(76px);

	&__info {
		display: flex;
		align-items: center;
		color: #000000;
		font-family: Raleway, sans-serif;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__infoIcon {
		width: rem(40px);
		height: rem(40px);
		margin-right: rem(8px);

		img {
			width: 100%;
		}
	}

	&__status {
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(88px);
		height: rem(32px);
		border-radius: rem(6px);
		font-size: rem(14px);
		font-weight: 600;
		letter-spacing: rem(0.14px);
		text-transform: capitalize;

		&_done {
			background-color: rgba(18, 212, 173, 0.2);
			color: #0d886f;
		}

		&_deploying {
			background-color: #e4eefe;
			color: #0c5ce5;
		}

		&_failed {
			background-color: rgba(255, 83, 42, 0.2);
			color: #e01616;
		}

		&_incomplete {
			background-color: rgba(255, 157, 43, 0.2);
			color: #d58221;
		}

		&_unknown {
			background-color: #dee3e8;
			color: #000000;
		}
	}
}
