@import '~@scss/functions';

.resourceItem {
	margin-right: rem(31px);
	padding: 0 !important;
	width: rem(168px) !important;
	height: rem(196px);
	border-radius: rem(6px);
	border: rem(1px) solid #d4e2f1;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color: #000000;

	&_selected {
		border: rem(1px) solid #0c5ce5;
		color: #0c5ce5;
		>.resourceItem__perHour {
			color: #0c5ce5;
		}
	}

	&_notAvailable {
		border: rem(1px) solid #d4e2f1;
		background-color: rgba(12, 92, 229, 0.02);
		color: #c5cad5;
		>.resourceItem__perHour, >.resourceItem__price {
			color: #c5cad5;
		}
	}

	&:hover:not(.resourceItem_notAvailable) {
		cursor: pointer;
		background-color: #f8fcfe;
	}

	&:nth-last-child(1) {
		margin-right: 0;
	}

	&__name {
		font-size: rem(24px);
		font-weight: 600;
		font-style: normal;
		letter-spacing: rem(0.24px);
		margin-top: rem(16px);
		margin-bottom: rem(13px);
	}

	&__cpuCores, &__memory {
		margin-bottom: rem(4px);
		&_text {
			font-size: rem(16px);
			font-weight: 400;
			font-style: normal;
			letter-spacing: rem(0.16px);
		}
		&_value {
			font-weight: 600;
		}
	}

	&__cpuCores {
		margin-bottom: rem(16px);
	}

	&__separator {
		width: rem(136px);
		height: rem(1px);
		background-color: #d4e2f1;
		margin-bottom: rem(16px);
	}

	&__price, &__perHour {
		color: #0c5ce5;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
	}
	&__price {
		color: #0c5ce5;
		margin-bottom: rem(4px);
	}

	&__perHour {
		color: #545a76;
	}
}
