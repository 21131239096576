@import '~@scss/functions';

.inputWithLabel {
	width: 100%;

	input[type="text"] {
		height: 100%;
		font-size: rem(16px);
		letter-spacing: rem(0.16px);
		line-height: rem(22px);
		font-weight: 400;
		background-color: #ffffff;
		border: rem(1px) solid #b9cee4;
		box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05);
		color: rgba(0, 0, 0, 1);

		&::placeholder {
			color: rgba(0, 0, 0, 0.4);
		}

		&:active, &:focus {
			border: rem(1px) solid #0c5ce5;
			box-shadow: 0 rem(3px) rem(6px) rgba(12, 92, 229, 0.2);
		}
	}

	.label {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f7fbff !important;
		font-size: rem(14px) !important;
		font-weight: 600;
		color: #104378 !important;
		border: rem(1px) solid #b9cee4 !important;
		&__error {
			border-color: red !important;
		}
	}
}