@import '~@scss/functions';

.popup {
	display: flex;
	flex-direction: column;
	min-width: rem(248px);

	&__header {
		box-sizing: border-box;
		margin:  0 rem(-16px);
		padding: 0 rem(11px) rem(13px) rem(11px);
		border-bottom: rem(1px) solid #d3e6fb;
		display: flex;
		justify-content: space-between;

		&_left, &_right {
			display: flex;
			align-items: center;
			flex-direction: row;
		}

		&_right {
			justify-content: flex-start;
		}

		&__flag {
			margin-right: rem(20px);
		}

		&__nodeName {
			height: rem(20px);
			color: #000000;
			font-weight: 600;
			text-transform: uppercase;
			font-size: rem(16px);
			letter-spacing: rem(0.16px);
		}

		&__nodeCount {
			margin-right: rem(24px);
			color: #545a76;
			font-size: rem(16px);
			font-weight: 600;
			font-style: normal;
			letter-spacing: rem(0.16px);
		}

		&__ctrl {
			display: flex;
			align-items: center;

			&_left, &_right {
				cursor: pointer;
			}

			&_left {
				margin-right: rem(16px);
			}

			&_right {
				transform: scale(-1, 1);
			}

			&_disabled {
				cursor: default;
			}
		}
	}

	&__content {
		height: rem(100px);
		padding: rem(7px) rem(12px);
		margin: 0 rem(-16px);
		display: flex;
		flex-direction: column;
		position: relative;

		// .layout
		>div {
			// .row
			>div {
				// .item
				div {
					&:nth-of-type(2) {
						justify-content: flex-end;
						// .measurementDataBar
						>div {
							>div {
								justify-content: center;
							}
						}
					}
				}
			}
		}
	}

	&__footer {
		padding: rem(12px) rem(16px) 0 rem(16px);
		margin: rem(12px) rem(-16px) 0;
		display: flex;
		align-items: center;
		border-top: rem(1px) solid #d3e6fb;

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: rem(26px);
			height: rem(26px);
			border-radius: 50%;
			background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
			margin-right: rem(16px);
		}

		.text {
			cursor: pointer;
		}
	}

	&__spinnerWrapper {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
