@import '~@scss/functions';

.dotElement {
	border-radius: 50%;
	display: inline-block;
	width: rem(12px);
	height: rem(12px);
	cursor: pointer;

	&_green {
		background-image: linear-gradient(to top, #18d6a6 0%, #00cdc8 100%);
	}
	&_orange {
		background-image: linear-gradient(to top, #f89728 0%, #f8bc23 100%);
	}
	&_red {
		background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
	}
	&_grey {
		background-color: #dee3e8;
	}
}