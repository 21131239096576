@import '~@scss/functions';

.localCache {
	.clusterSettings__content_wrapped__body__blockHead {
		margin-bottom: 0!important;
	}

	&__blockHead {
		height: rem(60px);
		color: #000000;
		font-size: rem(24px);
		font-weight: 600;
		letter-spacing: rem(0.24px);
		border-bottom: rem(1px) solid #d4e2f1;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__sections {
		display: flex;
		flex: 1;
		margin-bottom: rem(60px);

		&__section {
			min-height: rem(130px);
			width: 50%;
			display: flex;
			align-items: center;

			&__directory {
				display: flex;
				align-items: center;
				&__label {
					margin-right: rem(78px);
					color: #000;
					font-size: rem(16px);
					font-weight: 600;
					letter-spacing: rem(0.16px);
				}
				&__content {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: rem(248px);
					height: rem(46px);
					border-radius: rem(6px);
					border: rem(1px) solid #b9cee4;
					background-color: #fafdff;
					padding-left: rem(16px);
					padding-right: rem(10px);
					>span:nth-child(2) {
						margin: 0;
					}

					&__skeleton {
						width: rem(100px);
					}
				}
			}
		}
	}

	&__cacheInput {
		width: 100%;
		height: rem(46px);
		margin-bottom: 0;

		input {
			width: rem(319px);
			margin-right: rem(32px) !important;
		}

		&__skeleton {
			width: rem(192px);
			left: rem(210px);
		}
	}

	&__infoBox {
		width: 100%;
		height: 100%;
		background-image: linear-gradient(180deg, #f4faff 0%, #ffffff 100%);
		text-align: left;
		display: flex;
		align-items: center;
		border-left: rem(1px) solid #d4e2f1;
		padding: 0 rem(24px);

		p {
			color: #000000;
			font-size: rem(14px);
			font-weight: 400;
			font-style: normal;
			letter-spacing: rem(0.14px);
			line-height: normal;
			text-align: left;

			a {
				color: #104378;
				font-weight: 600;
				text-decoration: underline;
			}
		}
	}
}