@import '~@scss/functions';

.measurementDataBar {
	display: flex;
	min-width: rem(90px);
	height: rem(24px);
	border: rem(1px) solid #dae6f2;
	background-color: #fff;
	border-radius: rem(4px);
	overflow: hidden;

	&__textContainer {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		color: #104378;
		font-weight: 500;
		font-size: rem(16px);
		letter-spacing: rem(0.16px);
		line-height: rem(22px);

		&_data {
			border-right: rem(1px) solid #dae6f2;
			box-sizing: border-box;
			flex: 2;
		}

		&_measurement {
			background-color: #f7fbff;
		}
	}
}
