@import '~@scss/functions';

$black: #000000;
$gradientTwo: linear-gradient(180deg, #f4faff 0%, #ffffff 800px, #fefefe 801px, #fefefe 100%);
$darkBlue: #104378;

@media (max-width: 768px) {
	.login {
		width: rem(375px) !important;
		margin-left: auto;
		margin-right: auto;

		&__header {
			height: auto !important;
			padding-left: rem(16px) !important;
			padding-top: rem(30px) !important;
			padding-bottom: 0 !important;
			margin-bottom: rem(32px) !important;
			> button {
				display: none !important;
			}
		}

		.loginContent {
			display: flex;
			flex-direction: column;
			padding-top: 0 !important;

			&__logo {
				display: flex;
				width: 100% !important;
				justify-content: center !important;
				margin: 0 !important;
				margin-bottom: rem(32px) !important;
				>div {
					width: rem(128px) !important;
					height: rem(209px) !important;
					margin: 0 !important;
				}
			}


			&__form {
				max-width: rem(375px) !important;
				padding-left: rem(16px);
				padding-right: rem(16px);
				margin-bottom: rem(41px) !important;

				&__subheader {
					margin-bottom: rem(41px) !important;
				}

				.logoAppfleet {
					display: none !important;
				}

				>form {
					>div {
						// username input block
						&:nth-child(1) {
							margin-bottom: rem(16px) !important;
						}

						// password input block
						&:nth-child(2) {
							margin-bottom: rem(40px) !important;
						}
						>label {
							flex-direction: column !important;
							width: rem(343px) !important;
							align-items: flex-start !important;
							margin-bottom: 0 !important;

							>input {
								width: rem(343px) !important;
							}

							// error icon
							>span {
								bottom: rem(7px) !important;
								right: rem(7px) !important;
							}

							// error text
							>div:nth-last-child(1) {
								left: 0 !important;
								bottom: rem(-20px) !important;
								top: auto !important;
							}
						}
					}
				}
			}

			&__ctrls {
				width: auto !important;
				flex-direction: column !important;
				justify-content: flex-start !important;

				&__btns {
					width: 100% !important;
					margin-bottom: rem(24px) !important;
					flex-direction: row !important;
					justify-content: center !important;
					align-items: center !important;

					// do not show regular reset-pass span
					>span {
						display: none !important;
					}

					// reset span for mobile version
					&__reset {
						&_mobile {
							display: block !important;
							text-align: center;
							text-decoration: underline;
							font-weight: 400;
							font-style: normal;
							letter-spacing: rem(0.16px);
							line-height: normal;
						}
					}

					// create account mobile btn
					>button:nth-child(1) {
						background: #ffffff !important;
						display: inline-block !important;
						margin-right: rem(16px) !important;
						color: #104378 !important;
						width: rem(180px) !important;
					}

					// login btn
					>button:nth-child(2) {
						width: rem(147px) !important;
						height: rem(40px) !important;
					}
				}

				// error block
				>div:nth-child(2) {
					position: static !important;
					width: rem(343px) !important;
					margin-bottom: rem(24px) !important;
				}
			}
		}
	}
}

@media screen and (min-width: 1920px) {
	.login {
		width: rem(1360px) !important;
		margin-left: auto;
		margin-right: auto;
	}
}

.logoAppfleet {
	width: rem(248px);
	height: rem(48px);
}

.login {
	color: $black;
	height: 100%;

	&__header {
		margin-bottom: 0;
	}

	.loginContent {
		display: flex;
		align-items: flex-start;
		justify-content: center;

		&__logo {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
			max-width: rem(645px);
			margin-right: rem(105px);
			margin-top: rem(51px);

			>div:first-child {
				margin-left: rem(57px);
				width: rem(268px);
				height: rem(400px);
				background: url("../../../../assets/images/logo.login.png") no-repeat;
				background-size: contain;
				position: relative;
			}
		}
		&__form {
			flex-grow: 1;

			>.logoAppfleet {
				margin-bottom: rem(60px);
				position: relative;
			}

			&__header {
				color: $black;
				height: rem(29px);
				font-size: rem(24px);
				font-weight: 600;
				letter-spacing: rem(0.24px);
				margin-bottom: rem(8px);
			}
			&__subheader {
				color: $black;
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(0.16px);
				line-height: rem(22px);
				margin-bottom: rem(60px);
			}
		}

		&__ctrls {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-start;
			width: rem(566px);

			&__btns {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				width: rem(200px);

				&__reset {
					cursor: pointer;
					margin-top: rem(60px);
					color: $darkBlue;
					font-size: rem(16px);
					font-weight: 400;
					letter-spacing: rem(0.16px);

					&_mobile {
						display: none;
					}

					&:hover {
						text-decoration: underline;
					}

					&:active {
						opacity: 0.6;
						text-decoration: underline;
					}
				}
			}
		}
	}
}