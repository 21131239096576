@import '~@scss/functions';

.domainTable {
	tbody {
		tr {
			td:nth-child(1) {
				white-space: nowrap;
			}
		}
	}

	&__footerRow {
		td {
			border-bottom: rem(1px) solid #d4e2f1 !important;
		}

		&__mock {
			&_one, &_two {
				height: rem(8px);
				border-radius: rem(2px);
				background-color: #104378;
				opacity: 0.1;
			}

			&_one {
				width: rem(64px);
			}

			&_two {
				width: rem(88px);
			}
		}
	}
}

.domainTableItem {
	&__lock {
		max-width: rem(12px);
		margin-right: rem(5px);
	}
}

.domainRemoveCell {
	display: flex;
	cursor: pointer;
}


.domainStatusCell {
	display: flex;

	&__text {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: rem(11px);
		padding-right: rem(11px);
		height: rem(32px);
		text-align: center;
		border-radius: rem(6px);
		font-size: rem(14px);
		font-weight: 600;
		letter-spacing: rem(0.14px);
		background-color: rgba(18, 212, 173, 0.2);
		color: #0d886f;

		&_negative {
			margin-right: rem(10px);
			background-color: rgba(255, 83, 42, 0.2);
			color: #e01616;
		}

		&_warning {
			background-color: rgba(255, 157, 43, 0.2);
			color: #d58221;
		}
	}
}