@import '~@scss/functions';

.inputErrorText {
	width: 100%;
	height: rem(22px);
	line-height: rem(22px);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: rem(14px);
	font-weight: 400;
	color: #ff3232;
	padding-left: rem(4px);
	display: block;
}