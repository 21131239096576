@import '~@scss/functions';

.customModalWrap {
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2000;

}
.customModal {
	border-radius: rem(16px);
	background-color: #ffffff;
	position: fixed;
	margin: auto;
	filter: blur(0);
	-webkit-filter: blur(0);
}

@media screen and (min-height: rem(1001px)) {
	.customModalWrap {
		padding-bottom: 14%;
	}
}