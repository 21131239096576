
@import '~@scss/functions';

$white: #FFFFFF;
$defaultBorderRadius: rem(6px);

.passTooltip {
	width: rem(16px);
	height: rem(16px);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	margin-left: rem(6px);
	position: relative;
	background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);

	&__labelContent {
		width: rem(306px);
		height: rem(59px);
		border-radius: $defaultBorderRadius !important;
		background-color: rgba(0, 0, 0, 0.75) !important;
		color: $white !important;
		font-size: rem(14px) !important;
		font-weight: 400 !important;
		letter-spacing: rem(0.14px);
		position: absolute !important;
		bottom: rem(15px) !important;
		left: rem(-5px) !important;
		padding: rem(12px) rem(15px) rem(10px) rem(12px) !important;
		z-index: 1000;

		&::before {
			left: 4% !important;
			background-color: rgba(0, 0, 0, 0.75) !important;
			border-top-left-radius: rem(10px) !important;
		}
	}
}