@import '~@scss/functions';

.appSpinner {
	width: 100%;
	height: 100%;
	color: #0853D3;
	border-radius: 50%;
	position: relative;
	&:after, &:before {
		content: '';
		height: 0;
		width: 0;
		border: inherit;
		border: rem(5px) solid;
		border-radius: inherit;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		animation: circle-ripple  1s linear infinite;
	}

	&:before {
		animation-delay: -0.5s;
	}
}


@keyframes circle-ripple {
	0% {
		height: 0;
		width: 0;
	}
	100% {
		height: rem(48px);
		width: rem(48px);
		opacity: 0;
	}
}