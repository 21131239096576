@import '~@scss/functions';

.noMembers {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	>img {
		margin-top: rem(60px);
		margin-bottom: rem(12px);
		width: rem(322px);
		height: rem(269px);
	}

	&__header {
		height: rem(40px);
		color: #050d29;
		font-size: rem(32px);
		font-weight: 700;
		line-height: rem(40px);
		margin-bottom: rem(10px);
	}

	&__subHeader {
		height: rem(19px);
		color: #000000;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
	}
}