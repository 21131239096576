@import '~@scss/functions';

.wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: rem(40px);
	color: #000;

	.title {
		font-size: rem(24px);
		font-weight: 600;
		letter-spacing: rem(0.24px);
		line-height: 1;
		margin-bottom: rem(8px);
	}

	.subtitle {
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
		line-height: rem(22px);
	}
}