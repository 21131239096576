@import '~@scss/functions';


.appCard {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	border: rem(1px) solid #d4e2f1;
	background-color: #fff;
	border-radius: rem(6px);
}