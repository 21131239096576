@import '~@scss/functions';

.dateBtn {
	height: rem(32px) !important;
	font-size: rem(14px) !important;
	font-weight: 600 !important;
	letter-spacing: rem(0.14px) !important;
}

.nodeLocationsCard {
	&__head {
		display: flex;
		justify-content: space-between;
	}
}

.clusterInfo {
	.clusterHead {
		height: rem(160px);
	}

	&__dropdownBtn {
		z-index: 1;
		width: rem(196px);
		height: rem(40px);
		>div >div >div {
			height: rem(40px);
		}
	}
}

.cardsWrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	.overviewCard {
		position: relative;
		height: rem(215px);
		margin-bottom: rem(30px);

		&__statusSkeleton {
			width: rem(80px);
		}

		&__btnSkeleton {
			width: rem(178px);
		}

		&__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.title {
				font-size: rem(16px);
				font-weight: 400;
				color: #545a76;
				margin-bottom: rem(16px);
				text-transform: uppercase;
			}

			.content {
				display: flex;
				align-items: center;
				justify-content: center;

				&__icon {
					display: flex;
					align-items: center;
					justify-content: center;
					width: rem(26px);
					height: rem(26px);
					border-radius: 50%;
					background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
					margin-right: rem(8px);
				}

				&__text {
					display: flex;
					min-width: rem(24px);
					font-size: rem(32px);
					font-weight: 700;
					justify-content: flex-start;
					position: relative;

					.cardTextSkeleton {
						width: rem(16px);
						position: relative;
						height: rem(2px);
						top: 0;
						left: 0;
					}
				}
			}
		}
	}

	.imageCard {
		height: rem(176px);
		min-height: 0;
		position: relative;

		&__item {
			display: flex;

			&__containerLogo {
				display: flex;
				align-items: center;
				justify-content: center;
				width: rem(50px);
				height: rem(50px);
				border-radius: 50%;
				background-color: #eaf6ff;
				margin-right: rem(16px);
				>svg {
					width: rem(27px);
					height: rem(20px);
				}
			}

			&__pseudoInput {
				display: flex;
				align-items: center;
				border: rem(1px) solid #b9cee4;
				background-color: #fafdff;
				border-radius: rem(6px);
				color: #000000;
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(0.16px);
				line-height: rem(22px);
				width: rem(459px);
				height: rem(46px);
				padding-left: rem(16px);
				padding-right: rem(11px);

				&__icon {
					margin-right: rem(9px);
					display: none;
				}
				&__containerName {
					opacity: 0.4;
					flex: 1 1;
				}
				&__copyBtn {
					cursor: pointer;
				}
			}
		}
	}

	.dockerHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		>span {
			font-size: rem(16px);
			font-weight: 600;
			font-style: normal;
			letter-spacing: rem(0.16px);
		}
	}
}

.metricsSection {
	margin-top: rem(62px);
	background: #fefefe;

	&__head {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		height: rem(62px);

		.title {
			color: #000000;
			font-size: rem(24px);
			font-weight: 600;
			letter-spacing: rem(0.24px);
		}

		.selectWrapper {
			width: rem(132px);

			&__select {
				width: 100%;

				&.hidden {
					display: none;
				}

				>span {
					color: #104378;
					font-weight: 600;

					>svg {
						right: rem(8px);
					}
				}

			}

			&__option {
				width: 100%;
				color: #104378;
			}

			&__fakeInput {
				font-family: Raleway, sans-serif;
				font-size: rem(14px);
				font-weight: 600;
				letter-spacing: rem(0.14px);
				color: #104378;
			}
		}
	}
}

.cardHead {
	display: flex;
	justify-content: space-between;
}

.cardTextSkeleton {
	width: rem(24px);
	min-height: rem(26px);
	transform: scaleY(0.1);
}
