@import '~@scss/functions';

.demoBtn {
	font-size: rem(15px) !important;
	font-weight: 600 !important;
	width: rem(113px);
	height: rem(32px);
	position: absolute;
	top: rem(7px);
	right: rem(7px);
}

.dockerImageForm {

	>div {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		>div {
			padding-bottom: 0 !important;
			padding-top: 0 !important;

			&:nth-child(1) {
				margin-bottom: rem(12px);
			}
			&:nth-child(2) {
				margin-bottom: rem(25px);
			}
		}
	}
}
