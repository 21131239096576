@import '~@scss/functions';

.title {
	color: #050d29;
	line-height: rem(40px);
	font-size: rem(32px);
	font-weight: 700;
	margin: 0;

	&_uppercase {
		text-transform: uppercase;
	}
}