@import '~@scss/functions';

.searchWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: rem(367px);
	height: rem(46px);
	border-radius: rem(6px);
	border: rem(1px) solid #b9cee4;
	background-color: #ffffff;
	position: relative;
	transition: all 240ms ease;
	box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05);
	font-family: Raleway, sans-serif;

	&:focus {
		border: rem(1px) solid #0C5CE5;
		box-shadow: 0 rem(3px) rem(6px) rgba(12, 92, 229, 0.2);
		>.search__icon {
			border-right: rem(1px) solid #0C5CE5 !important;
		}
	}

	&_focused {
		border: rem(1px) solid #0C5CE5;
		box-shadow: 0 rem(3px) rem(6px) rgba(12, 92, 229, 0.2);
		>.search__icon {
			border-right: rem(1px) solid #0C5CE5 !important;
		}

		>div {
			background: #0C5CE5;
			border-radius: rem(6px) 0 0 rem(6px);
		}
	}

	.search__icon {
		transition: all 240ms ease;
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(46px);
		height: rem(46px);
		border-right: rem(1px) solid #b9cee4;
	}
	>input {
		width: rem(321px);
		height: 100%;
		border: none;
		padding: rem(12px) rem(16px);
		border-radius: rem(6px);
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
		line-height: rem(22px);

		&:focus {
			outline: none;
		}

		&::placeholder {
			color: rgba(0, 0, 0, 0.4);
		}
	}

	&__clear {
		position: absolute;
		right: rem(11px);
		cursor: pointer;
	}
}