@import '~@scss/functions';

.dot {
	width: rem(10px);
	height: rem(10px);
	position: relative;
	cursor: pointer;
	&::after {
		content: "";
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
		transform: scale(5.333);
		display: block;
		position: absolute;
		opacity: 0;
		top: 0;
		left: 0;
		transition: all 0.2s ease;
		pointer-events: none;
	}

	&_active {
		&::after {
			content: "";
			opacity: 0.15;
		}
	}
}