@import '~@scss/functions';

.nodeLocationsCard {
	position: relative;
	height: rem(421px) !important;

	&__cardHead {
		display: flex;
		justify-content: space-between;

		&__availabilityBadge {
			height: rem(32px);
			box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
			border-radius: rem(6px);
			border: rem(1px) solid #d3e6fb;
			background-color: #ffffff;
			display: flex;
			box-sizing: border-box;
			align-items: center;
			padding: 0 rem(8px);

			&__label {
				label {
					font-size: rem(14px);
				}

				&__tooltip {
					right: rem(-15px) !important;
					bottom: rem(30px) !important;
					left: auto !important;

					&::before {
						left: auto !important;
						right: rem(13px) !important;
					}
				}
			}

			&__status {
				width: rem(12px);
				height: rem(12px);
				border-radius: 50%;
				margin-right: rem(16px);
				background-color: #ff9d2b;

				&_green {
					background-color: #12d4ad;
				}
			}
		}
	}

	&__mapWrapper {
		width: 100%;
	}
}