@import '~@scss/functions';

.accountSettings {
	width: rem(1200px);
	margin-left: auto;
	margin-right: auto;

	&__head {
		display: flex;
		justify-content: space-between;
		margin-top: rem(57px);
		margin-bottom: rem(60px);
		height: rem(47px);

		&__header {
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
		}

		&__ctrls {
			&__edit {
				width: rem(63px);
				height: rem(40px);
			}
			&__discard {
				width: rem(91px);
				height: rem(40px);
				margin-right: rem(16px) !important;
			}
			&__save {
				width: rem(140px);
				height: rem(40px);
			}

			&__spinner {
				margin-right: rem(40px);
				top: rem(4px);
			}
		}
	}

	&__content {
		&__block {
			&__head {
				height: rem(22px);
				color: #545a76;
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(1.5px);
				line-height: rem(22px);
				text-transform: uppercase;
				margin-bottom: rem(30px);
			}

			&__body {
				display: flex;
				align-items: center;

				&__skeleton {
					right: rem(224px);
				}

				&__input {
					&:nth-child(2) {
						margin-left: rem(30px);
					}

					&_editMode {
						input {
							background: #ffffff;
							padding-right: rem(88px);
						}
					}
				}

				&__apiKeyInput {
					width: auto;
					input {
						width: rem(962px);
						background: #ffffff;
					}
				}

				&__newsSubscribe {
					display: flex;
					justify-content: space-between;
					width: rem(566px);
					align-items: center;
					margin-bottom: rem(40px);

					>span {
						color: #000000;
						font-size: rem(16px);
						font-weight: 600;
						letter-spacing: rem(0.16px);
					}
				}
			}
		}
	}
}

.apiKeyCtrls {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: rem(7px);

	&__copyBtn {
		width: rem(57px);
		height: rem(32px);
		margin-right: rem(12px) !important;
	}

	&__regenBtn {
		width: rem(107px);
		height: rem(32px);
	}
}