@import '~@scss/functions';

.removeItem {
	padding: rem(25px) rem(25px) rem(20px);
	border-radius: rem(16px);
	background: #ffffff;

	&__msgBlock {
		width: rem(513px);
		height: rem(88px);
		border-radius: rem(6px);
		border: rem(1px) solid #ff3232;
		background-color: #fff3f3;
		margin-bottom: rem(40px);
		display: flex;
		padding-left: rem(16px);
		padding-right: rem(20px);

		&__msg {
			color: #000000;
			font-size: rem(14px);
			font-weight: 400;
			letter-spacing: rem(0.14px);
			display: flex;
			align-items: center;
		}
		&__iconBlock {
			padding-top: rem(20px);
			margin-right: rem(16px);
		}
		&__icon {
			width: rem(32px);
			height: rem(32px);
		}
	}
	&__ctrls {
		display: flex;
		flex-direction: row;
		justify-content: center;

		&__dismiss {
			margin-right: rem(16px) !important;
			padding-left: rem(16px) !important;
			padding-right: rem(16px) !important;
			height: rem(40px);
			box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
			border-radius: rem(6px);
			border: rem(1px) solid #d3e6fb;
			background-color: #ffffff;
			color: #104378;
			font-size: rem(16px);
			font-weight: 700;
			letter-spacing: rem(0.16px);
		}
		&__agree {
			width: rem(96px);
			height: rem(40px);
			border-radius: rem(6px);
			background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
			color: #ffffff;
			font-size: rem(16px);
			font-weight: 700;
			letter-spacing: rem(0.16px);
		}
	}
}