@import '~@scss/functions';

.wrapper {
	height: 100%;
	margin-top: rem(-45px);
}

.deployStep {
	height: 100%;
	width: 100%;
	margin: 0 !important;
	display: flex;
	flex-direction: column !important;
	flex-wrap: nowrap !important;
}

.logsContainerRow {
	height: 100%;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.logsContainerColumn {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.accordionListCol {
	padding-right: rem(20px);
}

.progressBarWrapper {
	margin: rem(15px) 0;
	border-radius: rem(6px);
	position: relative;
	overflow: hidden;
}

.progressBar {
	height: rem(12px);
}

.progressBarFill {
	animation: animate-stripes 5s linear infinite;
	animation-duration: 10s;
}
@keyframes animate-stripes {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: rem(200px) 0;
	}
}

.stepLayoutImg {
	width: rem(123px);
	height: rem(106px);
}
.stepLayoutImgBlock {
	width: 15%;
}
.stepLayoutContentBlock {
	width: 85%;
}

.logsContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;

	:global(.terminal.xterm) {
		padding: rem(10px);
		margin-right: rem(8px);
		height: 99%;
	}
	:global(.xterm-viewport::-webkit-scrollbar-track) {
		background: none;
	}
	:global(.xterm-viewport::-webkit-scrollbar) {
		width: rem(2px);
	}
	:global(.xterm-viewport::-webkit-scrollbar-thumb) {
		border-radius: rem(1px);
		background-color: rgba(12, 92, 229, 0.3);
	}
}

.cardSection {
	padding: 0 !important;
}
