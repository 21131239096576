@import '~@scss/vars';
@import '~@scss/functions';

.subNav {
	border-bottom: rem(1px) solid #d4e2f1;

	&__content {
		display: flex;
		justify-content: space-between;
		width: $contentwidth;
		margin-right: auto;
		margin-left: auto;
		align-items: center;

		&__menu {
			display: flex;
			&__item {
				color: #000000;
				font-size: rem(16px);
				font-weight: 600;
				letter-spacing: rem(0.16px);
				padding: rem(20px) rem(18px) rem(19px) rem(18px);
				cursor: pointer;
				margin-bottom: rem(-1px);

				&_active {
					border-bottom: rem(2px) solid #00a6ff;
				}
			}
		}

		&__ctrls {
			flex: 1;
		}
	}
}