@import '~@scss/functions';

.accountBilling {
	&__contentWrapper {
		width: rem(1200px);
		margin-left: auto;
		margin-right: auto;
	}

	&__subNavWrapper {
		display: flex;
		width: 100%;

		&__subNav {
			width: 100%;
			>div {
				width: rem(1200px);
			}
		}
	}

	&__subNavList {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style: none;

	}

	&__subNavListItem {
		position: relative;
		padding: rem(20px) rem(18px);
		color: #000;
		font-family: Raleway, sans-serif;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
		border-bottom: rem(2px) solid transparent;
		cursor: pointer;

		&::before {
			position: absolute;
			bottom: rem(-3px);
			left: 0;
			content: "";
			display: none;
			width: 100%;
			height: rem(2px);
			background-color: #00a6ff;
		}

		&:hover::before, &_selected::before {
			display: flex;
		}
	}
}
