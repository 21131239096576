@import '~@scss/functions';

.icon {
	width: rem(52px);
	height: rem(46px);
	align-items: center;
	display: flex;
	background-size: rem(16px) rem(18px);
	background-position: center;
	background-repeat: no-repeat;
	cursor: pointer;
}