@import '~@scss/functions';

.clusterLogs {
	&__head {
		display: flex;
		justify-content: space-between;
		margin-bottom: rem(40px);
		padding-top: rem(20px);

		&__text {
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
		}
	}

	&__content {
		border: rem(1px) solid #d4e2f1;
		border-radius: rem(15px);
		background-color: #fff;
		overflow: hidden;

		&__head {
			width: 100%;
			margin-right: auto;
			margin-left: auto;
			display: flex;
			justify-content: left;
			align-items: center;
			height: rem(52px);
			padding-right: rem(60px);
			padding-left: rem(60px);
			overflow-x: auto;

			&__nodeRegion {
				height: 100%;
				padding-right: rem(16px);
				padding-left: rem(9px);
				display: flex;
				align-items: center;
				color: #000000;
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(0.16px);
				line-height: rem(22px);
				cursor: pointer;
				border-bottom: rem(2px) solid transparent;
				>i {
					width: rem(21px);
					height: rem(15px);
					margin-right: rem(12px);
				}
				&_active {
					border-bottom: rem(2px) solid #00a6ff;
					font-weight: 600;
				}

				&_combined {
					&__icon {
						display: flex;
						align-items: center;
						justify-content: center;
						width: rem(26px);
						height: rem(26px);
						border-radius: 50%;
						background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
						margin-right: rem(9px);
					}
				}
			}
		}

		&__divider {
			border-bottom: rem(1px) solid #d4e2f1;
		}

		&__body {
			height: rem(483px);
			&__logWrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				height: rem(391px);
				margin: rem(40px) rem(60px) 0;

				&_hidden {
					display: none;
				}
				>div {
					width: 100%;
				}
			}
		}
	}
}