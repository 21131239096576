@import '~@scss/functions';

.btnOne {
	background: linear-gradient(180deg, #0C5CE5 0%, #0853D3 100%) !important;
	color: #fff !important;
	border-radius: rem(6px);
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
	overflow: hidden;
	cursor: pointer;
	border: none !important;

	&:hover::before {
		opacity: 1;
	}
	&:active::before {
		opacity: 1;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.596664), rgba(0, 0, 0, 0.596664)), linear-gradient(180deg, #0853D3 0%, #0853D3 100%);
	}
	&.disabled {
		background-image: linear-gradient(180deg, #0853D3 0%, #0C5CE5 100%);
		opacity: 0.4;
	}

	&::before {
		position: absolute;
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, #073789 0%, #05327F 100%);
		transition: all 240ms ease;
		opacity: 0;
		z-index: -1;
	}
}

.btnEight {
	border-radius: rem(6px);
	margin: 0;
	padding: 0;
	box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
	border: rem(1px) solid #d3e6fb;
	background-color: #ffffff;
	color: #104378;
	font-size: rem(14px);
	font-weight: 600;
	letter-spacing: rem(0.14px);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	overflow: hidden;

	&::before {
		position: absolute;
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, #0C5CE5 0%, #0853D3 100%);
		transition: all 240ms ease;
		opacity: 0;
		z-index: -1;
	}

	&:hover::before {
		opacity: 1;
	}

	&:hover {
		color: #ffffff;
	}

	&:active {
		color: #ffffff;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.396664), rgba(0, 0, 0, 0.396664)), linear-gradient(180deg, #0C5CE5 0%, #0853D3 100%);
	}
}