
@import '~@scss/functions';

$black: #000;

.noClusters {
	align-items: center;
	justify-content: center;
	height: calc(100vh - 490px);
	min-height: rem(450px);
	display: flex;
	flex-direction: column;
	width: 100%;

	&__title {
		height: rem(40px);
		color: #050d29;
		font-size: rem(32px);
		font-weight: 700;
		line-height: rem(40px);
		margin-bottom: rem(14px);
	}

	&__descr {
		width: rem(616px);
		height: rem(19px);
		color: $black;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
		margin-bottom: rem(40px);
	}

	&__image {
		width: rem(240px);
		margin-bottom: rem(16px);
	}
}