@import '~@scss/functions';

.subtitleText {
	display: flex;
	flex-direction: column;

	&__text {
		font-size: rem(16px);
		font-weight: 400;
		color: #000;
		letter-spacing: rem(0.16px);
		margin-bottom: rem(6px);
		line-height: 1;
	}

	&__subtitle {
		font-size: rem(14px);
		font-weight: 400;
		opacity: 0.5;
		color: #000;
		line-height: 1;
	}
}