@import '~@scss/functions';

.layout {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: center;
	font-size: rem(16px);

	.imageWrapper {
		width: rem(400px);

		.image {
			width: 100%;
		}
	}

	.pageTitle {
		margin-top: rem(-70px);
		font-size: rem(32px);
		font-weight: 700;
		line-height: 1;
		margin-bottom: 0;
	}

	.subtitle {
		font-weight: 400;
		font-size: rem(16px);
		letter-spacing: rem(0.16px);
		margin: rem(10px) 0 rem(40px);
	}
}

@media (-webkit-min-device-pixel-ratio: 2) {
	.layout {
		.imageWrapper {
			width: rem(800px);
		}

		.pageTitle {
			margin-top: rem(-140px);
			font-size: 4em;
		}

		.subtitle {
			font-size: 2em;
		}
	}
}