@import '~@scss/functions';

.grid {
	height: 100%;
}

.contentRow {
	margin-bottom: rem(38px);
}

.mapWrapper {
	flex: 1;
	padding-left: rem(38px);
	position: relative;

	.hint {
		position: absolute;
		left: rem(50px);
		height: rem(32px);
		box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
		border-radius: rem(6px);
		border: rem(1px) solid #d3e6fb;
		background-color: #ffffff;
		display: flex;
		box-sizing: border-box;
		align-items: center;
		padding: 0 rem(8px);
		z-index: 10;

		.hintLabel {
			label {
				font-size: rem(14px);
			}
		}

		.hintLabelIcon {
			background-color: #0a57dc;
			border-radius: 50%;
		}

		.status {
			width: rem(12px);
			height: rem(12px);
			border-radius: 50%;
			margin-right: rem(16px);

			&__good {
				background-color: #12d4ad;
			}

			&__warn {
				background-color: #ff9d2b;
			}
		}
	}

	.map {
		width: 100%;
	}
}

.regionListWrapper {
	flex-basis: rem(316px);
	max-height: rem(370px);
	height: 100%;
	overflow-x: hidden;

	.list {
		margin: rem(-16px) 0;
		padding: 0;


		&_item {
			margin: rem(16px) 0;
			padding: 0;
			max-width: rem(268px);
			border-radius: rem(6px);
			background-color: #fff;
			overflow: hidden;

			.active {
				border: rem(1px) solid #0c5ce5;
			}

			.input {
				width: rem(99px);
				max-width: rem(99px);
				height: rem(32px);
			}
		}
	}
}

.scrollThumb {
	width: rem(2px);
	border-radius: rem(1px);
	background-color: #0c5ce5;
	opacity: 0.3;
}

.controls {
	display: flex;
	justify-content: space-between;
	width: 100%;
	&_singleBtn {
		justify-content: flex-end;
	}
}

.regionsStepMeta__priceBar {
	margin-top: rem(60px);
}

.regionsStep {
	display: flex;
}

@media screen and (min-height: rem(767px)) {
	.regionListWrapper {
		max-height: rem(425px);
		min-height: rem(425px);
	}
}
