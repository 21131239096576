@import '~@scss/functions';

.tooltip {
	color: #000;
	pointer-events: none;
	border-radius: rem(6px);
	position: absolute;
	background-color: #fff;
	padding: rem(10px);
	z-index: 100;
	border: rem(1px) solid #d3e6fb;
	box-sizing: border-box;
	height: auto;
	width: auto;


	.tooltipContentWrapper {
		height: auto;
		display: flex;
		flex-direction: column;
	}

	.labelActive {
		border-radius: rem(6px);
		font-weight: 700;
		background-color: #e4eefe;
	}

	.dataRow {
		padding: rem(3px);
		height: rem(28px);
		box-sizing: border-box;
		display: flex;
		width: auto;

		.labelWrap, .contentWrap {
			display: flex;
			align-items: center;
		}

		.labelWrap {
			flex: 1;
			padding-right: rem(8px);
			.label {
				letter-spacing: rem(0.16px);
				line-height: rem(22px);
				flex-grow: 1;
			}
			.flagIcon {
				margin-right: rem(8px);
			}
		}

		.contentWrap {
			padding-left: rem(8px);
		}

		.dataColorCircle {
			width: rem(12px);
			height: rem(12px);
			border-radius: 50%;
			background-color: blue;
			margin-right: rem(8px);
		}
	}

	p {
		margin: 0;
	}

	&_hidden {
		visibility: hidden;
	}
	&_withScroll {
		height: rem(170px);
	}

	&__pin {
		position: absolute;
		bottom: rem(-10px);
	}

	&_noBandwidth {
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(53px);
		height: rem(31px);
		box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
		color: #0c5ce5;
		font-size: rem(16px);
		font-weight: 600;
		font-style: normal;
		letter-spacing: rem(0.16px);

		&::before {
			content: '';
			bottom: auto;
			top: 100%;
			margin-top: rem(1px);
			border-width: 0 rem(1px) rem(1px) 0;
			right: auto;
			transform: translateX(-50%) translateY(-50%) rotate(45deg);
			left: 50%;
			position: absolute;
			background-image: none;
			z-index: 1001;
			width: rem(11px);
			height: rem(11px);
			transition: background .1s ease;
			border-style: solid;
			border-color: inherit;
			background-color: inherit;
		}
	}
}

.pointer {
	width: rem(20px);
	height: rem(20px);
	border-radius: 50%;
	background-color: blue;
	position: absolute;
}

.bottomShadowBlock {
	position: absolute;
	bottom: rem(-1px);
	left: 0;
	width: 100%;
	height: rem(56px);
	background-image: linear-gradient(180deg, transparent 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 100%);
}
