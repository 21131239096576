@import '~@scss/functions';

.addRegistry {
	width: rem(367px);
	min-height: rem(450px);
	border-radius: rem(16px);
	background-color: #ffffff;
	padding: 0 rem(25px) rem(20px) rem(25px);
	display: flex;
	flex-direction: column;


	&__close {
		position: absolute;
		right: 0;
		top: rem(-34px);
		cursor: pointer;
		width: rem(20px);
		height: rem(20px);

		.hidden {
			pointer-events: none;
			visibility: hidden;
		}
	}

	&__icon {
		position: absolute;
		top: rem(-43px);
		left: rem(141px);
		width: rem(86px);
		height: rem(86px);
	}

	&__header {
		padding-top: rem(59px);
		text-align: center;
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__msg, &__errMsg {
		padding: rem(8px);
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		line-height: rem(17px);
		margin-top: rem(20px);
		margin-bottom: rem(20px);
		margin-right: auto;
		margin-left: auto;
		width: rem(317px);
		height: rem(54px);
		border-radius: rem(6px);
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
	}
	&__msg {
		border: rem(1px) solid #d4e2f1;
		background-color: #f8fcfe;
		color: #545a76;
	}
	&__errMsg {
		border: rem(1px) solid #ff3232;
		background-color: #fff3f3;
		color: #000000;
	}

	&__saveBtn {
		width: rem(164px);
		height: rem(40px);
		color: #ffffff;
		font-size: rem(16px);
		font-weight: 700;
		letter-spacing: rem(0.16px);
		box-sizing: border-box;
	}

	&__wrapSelect {
		width: 100%;
		height: rem(46px);

		&__label {
			width: rem(107px);
			display: none;
		}

		&__select {
			height: rem(46px);
			width: rem(210px);
			padding-right: rem(8px);
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);

			&__options {
				top: rem(46px) !important;
				width: rem(210px);
				&__option {
					width: rem(198px);
					height: rem(34px);
				}
			}
		}
	}

	&__regInput {
		&_input {
			height: rem(46px);
		}
	}

	&__form {
		margin-bottom: rem(20px) !important;
	}
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.registryInput {
	width: rem(317px);
	height: rem(46px);
	margin-left: auto;
	margin-right: auto;
	margin-bottom: rem(20px);

	&:nth-child(3) {
		margin-bottom: rem(40px);
	}


	div:first-child {
		width: rem(107px);
	}
	input {
		width: rem(210px);
	}
}