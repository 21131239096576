@import '~@scss/functions';

@media (max-width: 768px) {
	.newPass {
		width: rem(375px) !important;
		margin-left: auto;
		margin-right: auto;

		&__header {
			height: auto !important;
			padding-left: rem(16px) !important;
			padding-top: rem(30px) !important;
			padding-bottom: 0 !important;
			margin-bottom: rem(217px) !important;
			> button {
				display: none !important;
			}
		}

		&__content {
			width: rem(343px) !important;
			margin-left: auto;
			margin-right: auto;
			padding-top: 0 !important;
			&__header {
				width: rem(343px) !important;
				margin-top: 0 !important;
				text-align: left !important;
			}
			&__subHeader {
				width: rem(343px) !important;
				text-align: left !important;
				margin-bottom: rem(40px) !important;
			}

			&__passwordProgress {
				width: rem(343px) !important;
				margin-left: 0 !important;
				top: rem(74px) !important;
				// password strength blocks
				>div {
					width: rem(77px) !important;
					margin-right: rem(12px) !important;
					&:nth-last-child(1) {
						margin-right: 0 !important;
					}
				}
			}

			&__form {
				>label {
					flex-direction: column !important;
					width: rem(343px) !important;
					align-items: flex-start !important;
					margin-bottom: rem(52px) !important;

					>input {
						width: rem(343px) !important;
					}

					// error icon
					>span {
						bottom: rem(7px) !important;
						right: rem(7px) !important;
					}

					:nth-child(4) {
						left: 0 !important;
						bottom: rem(-20px) !important;
						top: rem(74px) !important;
					}
					// error text
					:nth-child(5) {
						bottom: rem(-30px) !important;
						left: 0 !important;
						top: rem(78px) !important;
					}
				}
				>button {
					margin-top: 0 !important;
					margin-bottom: rem(32px) !important;
				}

				&__error {
					width: rem(343px) !important;
					height: auto !important;
					position: static !important;
					margin-top: 0 !important;
					margin-bottom: rem(32px) !important;
				}
			}
		}
	}
}

.newPass {
	&__header {
		margin-bottom: 0;
	}

	&__content {
		color: #000000;
		&__header {
			font-size: rem(24px);
			font-weight: 600;
			letter-spacing: rem(0.24px);
			margin-bottom: rem(8px);
			margin-top: rem(75px);
			text-align: center;
		}
		&__subHeader {
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);
			text-align: center;
			margin-bottom: rem(60px);
		}
		&__form {
			align-items: center;
			display: flex;
			justify-content: center;
			flex-direction: column;

			&__error {
				margin-top: rem(40px);
				width: rem(566px);
				height: rem(77px);
				margin-left: auto;
				margin-right: auto;
			}
		}
		&__btnSavePass {
			width: rem(149px);
			height: rem(40px);
			margin-top: rem(20px) !important;
		}
	}
}
.btnCreateAccount {
	width: rem(175px);
	height: rem(40px);
}