@import '~@scss/functions';

.repositories {
	display: flex;
	flex-direction: column;

	.repositoriesHeading {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: rem(32px);

		.label {
			font-weight: 600;
			letter-spacing: rem(0.16px);
		}
	}

	.icon {
		width: rem(24px);
		height: rem(24px);
		box-sizing: border-box;
		&__active {
			box-shadow: 0 0 rem(8px) rem(1px) #0853D3;
		}
		//  styles of the tooltip of the icon
		>div {
			left: auto;
			&::before {
				left: calc(50% - 10px);
			}
		}
	}
	.iconWrapper {
		width: rem(50px);
		height: rem(50px);
		margin-right: rem(12px);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		&__blank {
			background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
		}
		&__repository {
			background-color: #eaf6ff;
		}
	}

	.repositoriesList {
		margin: 0;
		display: flex;
		flex-wrap: nowrap;
		.icon {
			margin-right: rem(32px);
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.emptyList, .addRepoCtrls {
		display: flex;
		align-items: center;
		span {
			font-weight: 400;
		}
	}

	.addRepoCtrls {
		&__addIcon {
			width: rem(50px);
			height: rem(50px);
			background-color: #eaf6ff;
			border-radius: 50%;
			cursor: pointer;
			margin-right: rem(16px);

			svg {
				width: rem(20px);
				height: rem(20px);
			}

			&_active{
				background-color: #0954d6;
			}
		}
	}
}
