@import '~@scss/functions';

.billingInformationInfo {
	&__btnGroup {
		display: flex;

		& > :first-child {
			margin-right: rem(10px) !important;
		}
	}

	&__formBlockTitle {
		color: #545a76;
		font-family: Raleway, sans-serif;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(1.5px);
		line-height: rem(22px);
		text-transform: uppercase;
	}

	&__selectWrapper {
		width: rem(367px);
		height: rem(46px);
	}

	&__select {
		width: 100%;
		height: 100%;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
		line-height: rem(22px);
	}

	&__selectOptions {
		top: rem(45px) !important;
		width: 100%;
		max-height: rem(300px);
		overflow: auto;

		>div {
			height: rem(300px);
		}

		.selectWithSearch__select__options_top {
			top: rem(-45px) !important;
		}
	}

	&__billingEmailIconTooltip {
		bottom: rem(45px);
		z-index: 1;
	}

	&__zipcode {
		&__label {
			&_error {
				color: #ff9d2b !important;
			}
		}

		&__input {
			&_error {
				>input {
					border: rem(1px) solid #ff9d2b !important;
					box-shadow: 0 rem(3px) rem(6px) rgba(255, 157, 43, 0.2);

					&:active, &:focus {
						box-shadow: 0 rem(3px) rem(6px) rgba(255, 157, 43, 0.2);
						border: rem(1px) solid #ff9d2b !important;
						outline: none !important;
					}
				}
			}
		}
	}

	&__spinner {
		margin-right: rem(40px);
		top: rem(4px);
	}

	&__closeBtn {
		margin-right: rem(4px) !important;
	}
}
