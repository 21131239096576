@import '~@scss/functions';

$black: #000000;
$gradientTwo: linear-gradient(180deg, #f4faff 0%, #ffffff rem(800px), #fefefe rem(801px), #fefefe 100%);
$mainBlue: #0C5CE5;


@media (max-width: rem(768px)) {
	.account {
		width: rem(375px) !important;
		margin-left: auto;
		margin-right: auto;

		&__header {
			height: auto !important;
			padding: rem(24px) rem(20px) 0 rem(16px) !important;
			margin-bottom: rem(26px) !important;
			// logo
			>div {
				margin-top: rem(6px) !important;
			}
			// btn log in
			>button {
				width: rem(90px) !important;
				height: rem(40px) !important;
			}
		}

		// show logo only for mobile version
		&__logo_mobile {
			display: flex !important;
			flex-grow: 1;
			justify-content: center;
			margin-bottom: rem(32px);

			>div:first-child {
				width: rem(128px);
				height: rem(209px);
				background: url("~@images/logo.login.png") no-repeat;
				background-size: contain;
				position: relative;
			}
		}

		.accountContent {
			padding-top: 0 !important;
			width: rem(343px) !important;
		}

		.accountContentTitleHeader {
			display: none !important;

			&_mobile {
				display: block !important;
			}
		}

		.accountContentTitleSubheader {
			display: none !important;

			&_mobile {
				display: block !important;
			}
		}

		.accountContentTitle {
			margin-bottom: rem(41px) !important;
		}

		.accountContentForm {
			.accountContentFormPair {
				margin-bottom: rem(16px) !important;
				height: auto !important;

				&__passwordProgress {
					margin-left: 0 !important;
					top: rem(74px) !important;
					// password strength blocks
					>div {
						width: rem(77px) !important;
						margin-right: rem(12px) !important;
						&:nth-last-child(1) {
							margin-right: 0 !important;
						}
					}
				}

				// pair of password repeat-password
				&:nth-child(3) {
					>label {
						&:nth-child(1) {
							margin-bottom: rem(36px) !important;
							// password strength
							:nth-child(4) {
								top: rem(74px) !important;
							}
							// error text
							:nth-child(5) {
								bottom: rem(-30px) !important;
								left: 0 !important;
								top: rem(78px) !important;
							}
						}
					}
				}

				>label {
					&:nth-child(1) {
						margin-bottom: rem(16px) !important;
					}
					&:nth-child(2) {
						margin-bottom: 0 !important;
					}

					flex-direction: column !important;
					width: rem(343px) !important;
					align-items: flex-start !important;
					margin-right: 0 !important;

					>input {
						width: rem(343px) !important;
					}

					// error icon
					>span {
						bottom: rem(7px) !important;
						right: rem(7px) !important;
					}

					// error text
					:nth-child(4) {
						left: 0 !important;
						bottom: rem(-20px) !important;
						top: auto !important;
					}
				}
			}

			.accountContentFormService {
				margin-top: rem(40px) !important;
				margin-bottom: rem(40px) !important;
				flex-direction: column;

				.accountContentFormServiceTerms {
					width: rem(343px) !important;
					>div {
						margin-bottom: 0 !important;
					}

					.termsLink {
						text-decoration: underline;
					}
				}

				.accountContentFormServiceError {
					>div {
						margin-top: rem(42px) !important;
						bottom: 0 !important;
						margin-bottom: 0 !important;
					}
				}
			}
			.accountContentFormCtrls {
				justify-content: flex-start !important;
				margin-bottom: rem(32px) !important;

				&__createBtn {
					display: none !important;
					&_mobile {
						display: flex !important;
						width: rem(200px) !important;
						height: rem(40px) !important;
						align-items: center;
						// text of the mobile btn
						>div {
							display: flex !important;
							padding: 0 rem(24px) !important;
							// spinner
							>div {
								margin-right: rem(2px) !important;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: rem(1920px)) {
	.account {
		width: rem(1360px) !important;
		margin-left: auto;
		margin-right: auto;
	}
}

.account {
	color: $black;
	height: 100%;

	&__header {
		margin-bottom: 0;
	}

	&__logo_mobile {
		display: none;
	}

	.accountContent {
		width: rem(1162px);
		margin-left: auto;
		margin-right: auto;

		.accountContentTitle {
			color: $black;
			margin-bottom: rem(44px);


			.accountContentTitleHeader {
				font-size: rem(24px);
				font-weight: 600;
				letter-spacing: rem(0.24px);
				margin-bottom: rem(9px);

				&_mobile {
					display: none;
				}
			}
			.accountContentTitleSubheader {
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(0.16px);
				line-height: rem(22px);

				&_mobile {
					display: none;
				}
			}
		}
		.accountContentForm {
			width: 100%;

			.accountContentFormPair {
				display: flex;
				flex-wrap: wrap;
				height: rem(46px);
				margin-bottom: rem(40px);

				&:last-of-type {
					margin-bottom: rem(60px);
				}

				>label:first-child {
					margin-right: rem(30px);
				}
			}

			.accountContentFormService {
				display: flex;
				margin: rem(16px) 0;

				.accountContentFormServiceTerms {
					display: flex;
					flex-direction: column;
					width: rem(795px);

					>div {
						display: flex;

						&:first-child {
							margin-bottom: rem(21px);
						}

						>span {
							color: #545a76;
							font-size: rem(16px);
							font-weight: 400;
							letter-spacing: rem(0.16px);
							line-height: rem(24px);
							margin-left: rem(20px);
							margin-right: rem(5px);
							cursor: pointer;
						}

						.termsLink {
							color: $mainBlue;
							font-weight: 600;
							cursor: pointer;
							margin-left: 0;
						}
					}

					&_checked {
						>span {
							color: $black !important;
						}

						.termsLink {
							color: $mainBlue !important;
						}
					}
				}
				.accountContentFormServiceError {
					display: flex;
				}
			}

			.accountContentFormCtrls {
				display: flex;
				width: 100%;
				justify-content: flex-end;
				&__createBtn {
					&_mobile {
						display: none;
					}
				}
			}
		}
	}
}

@media screen and (min-height: rem(767px)) {
	.account {
		.accountContent {
			.accountContentTitle {
				margin-bottom: rem(60px);
			}

			.accountContentForm {
				.accountContentFormService {
					margin-bottom: rem(40px);
					margin-top: rem(60px);
				}
			}
		}
	}
}
