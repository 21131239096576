.progressbar {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&_singleStep {
		justify-content: flex-end;
	}

	&_background {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto 0;
		z-index: 1;
		background-color: transparent;
	}

	&_bar {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto 0;
		z-index: 2;
		transition: all 0.1s ease;

		&_isDragging {
			transition-delay: 0ms;
			transition-duration: 0ms;
		}
	}

	&_dot {
		position: relative;
		transition: all 0.4s ease;
		z-index: 5;

		&_isDragging {
			transition-delay: 0ms;
			transition-duration: 0ms;
		}

		&_isActive {
			z-index: 100;
			position: absolute;
		}
	}
}