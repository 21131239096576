@import '~@scss/functions';

.centeredLayout {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	padding-top: rem(40px);

	&__header {
		text-align: center;
	}

	&__content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		align-items: center;
		box-sizing: border-box;
	}

	&__controlsRow {
		padding: rem(32px) 0;
		width: 100%;
		max-width: rem(963px);
	}
}

@media screen and (min-height: rem(1200px)) {
	.centeredLayout {
		&__content {
			flex: 1;
		}

		&__controlsRow {
			margin-top: rem(32px);
			flex: 2;
		}
	}
}
