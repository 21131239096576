@import '~@scss/functions';

.activityLog {

	.paginationContainer {
		margin: rem(40px) 0;
	}

	&__head {
		width: rem(1160px);
		padding-right: rem(39px);
		margin-right: auto;
		margin-left: auto;

		display: flex;
		justify-content: space-between;
		margin-bottom: rem(40px);
		padding-top: rem(20px);

		&__text {
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
		}
	}

	.emptyListMessage {
		font-size: rem(24px);
		font-weight: 600;
		text-align: center;
	}

	.btn {
		width: 100%;
		height: rem(32px);
	}

	.cell {
		&__btn {
			width: rem(232px);
		}

		&__centered {
			div {
				justify-content: center;
			}
		}
	}
}