@import '~@scss/functions';

.nodeStatus {
	display: flex;
	box-sizing: border-box;
	border-radius: rem(6px);
	justify-content: center;
	align-items: center;
	padding-left: rem(16px);
	padding-right: rem(8px);
	height: rem(32px);

	&__noIcon {
		padding-right: rem(16px);
	}

	&__text {
		padding: rem(4px);
		font-size: rem(14px);
		font-weight: 600;
		letter-spacing: rem(0.14px);
		line-height: rem(16px);
		display: flex;
	}

	&.new, &.healthy {
		background-color: rgba(18, 212, 173, 0.2);
		color: #0d886f;
	}

	&.deploying {
		background-color: #e4eefe;
		color: #0c5ce5;
	}

	&.unhealthy {
		background-color: rgba(255, 157, 43, 0.2);
		color: #d58221;
	}

	&.down {
		background-color: rgba(255, 83, 42, 0.2);
		color: #e01616;
	}

	&.unknown {
		background-color: #dee3e8;
		color: #000000;
	}

	&__statusIcon {
		display: flex;
		width: rem(16px);
		height: rem(16px);
		background-image: none;

		&.new, &.healthy {
			background-image: linear-gradient(to top, #18d6a6 0%, #00cdc8 100%);
		}

		&.unhealthy {
			background-image: linear-gradient(to top, #f89728 0%, #f8bc23 100%);
		}

		&.deploying {
			background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
			>span {
				animation: rotating 1.5s linear infinite;
			}
		}

		&.down {
			background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
		}

		&.unknown {
			background-color: #000000;
		}
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}