@import '~@scss/functions';

.intercomNotification {
	position: fixed;
	z-index: 9999;
	right: rem(20px);
	bottom: rem(20px);

	display: flex;
	flex-direction: column;
	align-items: flex-end;

	max-width: rem(450px);

	&_hidden {
		display: none;
	}

	&__card {
		display: flex;
		align-items: flex-start;

		max-width: rem(450px);
		padding: rem(12px);
		margin-bottom: rem(15px);

		background-color: #fff;

		border-radius: rem(5px);
		border: none;
		box-shadow: 0 rem(3px) rem(32px) rgba(0, 0, 0, .14);

		transition: right 240ms ease;
	}

	&__infoBlock {
		display: flex;
		align-items: center;
	}

	&__icon {
		width: rem(32px);
	}

	&__text {
		margin-left: rem(15px);
		font-size: rem(18px);
		line-height: 1.4;
		letter-spacing: rem(0.18px);
		color: #545a76;
	}

	&__closeCardIcon {
		width: rem(10px);
		cursor: pointer;

		svg {
			display: flex;
			width: rem(16px);
		}
	}

	&__disabledImgBlock {
		width: rem(60px);
		height: rem(60px);
		overflow: hidden;
		cursor: pointer;
	}

	&__disabledImg {
		width: 100%;
		height: 100%;
	}
}
