@import '~@scss/functions';

.sortingCtrls {
	width: rem(26px);
	display: flex;
	flex-direction: column;
	height: rem(34px);
	position: relative;
	left: rem(-4px);
	font-size: rem(12px);
	font-weight: 400;
	color: #000000;

	&__angle {
		width: rem(26px);
		height: rem(17px);
		cursor: pointer;
		padding: rem(2px) rem(8px) rem(8px);

		&_up {
			transform: rotateX(-180deg);
		}
	}
}