@import '~@scss/functions';

$black: #000000;
$mainBlue: #0c5ce5;
$gradientTwo: linear-gradient(180deg, #f4faff 0%, #ffffff rem(800px), #fefefe rem(801px), #fefefe 100%);


@media (max-width: rem(768px)) {
	.checkEmail {
		width: rem(375px) !important;
		margin-left: auto;
		margin-right: auto;

		&__header {
			height: auto !important;
			padding-left: rem(16px) !important;
			padding-top: rem(30px) !important;
			padding-bottom: 0 !important;
			margin-bottom: rem(115px) !important;
			> button {
				display: none !important;
			}
		}

		.checkEmailLogo{
			width: rem(275px) !important;
			height: rem(258px) !important;
		}

		.checkEmailContent {
			width: rem(343px) !important;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: rem(25px) !important;
			.checkEmailContentHeader {
				width: rem(343px) !important;
				font-size: rem(24px) !important;
				font-weight: 600;
				letter-spacing: rem(0.24px) !important;
			}
			.checkEmailContentSubheader {
				width: rem(343px) !important;
				height: auto !important;
			}
		}

		.checkEmailCtrls {
			margin-bottom: rem(32px) !important;
		}
	}
}

@media screen and (min-width: rem(1920px)) {
	.checkEmail {
		width: rem(1360px) !important;
		margin-left: auto;
		margin-right: auto;
	}
}

.checkEmail {
	color: $black;
	height: 100%;

	.checkEmailLogo {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		width: rem(275px);
		height: rem(258px);
		margin-top: rem(-21px);
		margin-bottom: rem(40px);
		background: url("../../../../assets/images/logo.check.email.png") no-repeat;
		background-size: contain;
	}
	.checkEmailContent {
		margin-bottom: rem(61px);

		.checkEmailContentHeader {
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
			width: rem(373px);
			height: rem(40px);
			margin-bottom: rem(8px);
		}
		.checkEmailContentSubheader {
			position: relative;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			width: rem(584px);
			height: rem(43px);
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);

			.emailText {
				color: $mainBlue;
				font-weight: 600;
			}
		}
	}
	.checkEmailCtrls {
		display: flex;
		justify-content: center;

		.checkEmailCtrlsHomeBtn {
			margin-right: rem(20px);
			width: rem(162px);
			height: rem(40px);
			box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
			border-radius: rem(6px);
			border: rem(1px) solid #d3e6fb;
			background-color: #ffffff;
			color: #104378;
			font-size: rem(16px);
			font-weight: 700;
			letter-spacing: rem(0.16px);
		}
		.checkEmailCtrlsResendBtn {
			width: rem(205px);
			height: rem(40px);
			border-radius: rem(6px);
			background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
			color: #ffffff;
			font-size: rem(16px);
			font-weight: 700;
			letter-spacing: rem(0.16px);
		}
	}
}