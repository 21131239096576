@import '~@scss/functions';

.wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.content {
		flex: 2;
	}
}

.repositoriesWrapper {
	margin-bottom: rem(40px);
}

.controls {
	display: flex;
	justify-content: space-between;
}

.mainImg {
	width: rem(328px);
	height: rem(355px);
}