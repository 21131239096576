@import '~@scss/functions';

@font-face {
	font-family: "OCRAStd";
	src: local('OCRAStd'), url("../fonts/OCRAStd.ttf") format("truetype")
}

.notFoundLayout {
	padding-top: rem(16px);
}
