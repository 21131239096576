
@import '~@scss/functions';

.rebuildOrDelete {
	margin-bottom: rem(60px);

	>div:nth-child(2) {
		margin-bottom: rem(38px);
	}

	&__logicBlock {
		display: flex;
		flex-direction: column;

		&__btn {
			width: rem(184px) !important;
			height: rem(40px) !important;
		}

		&__descr {
			color: #545a76;
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			margin-top: rem(16px);
		}
	}
}