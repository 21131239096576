@import '~@scss/functions';

.billingPaymentInfo {
	display: flex;
	flex-wrap: wrap;
}

.addPaymentCardBtn {
	&__addCardTitle {
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__cardLayout {
		width: rem(367px);
		height: rem(220px);
		border-radius: rem(16px);
		border: rem(1px) solid #d4e2f1;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;

		&__cardStylization {
			width: rem(143px);
			height: rem(86px);
			overflow: visible;
			margin-top: rem(42px);
			margin-bottom: rem(30px);
			position: relative;
			box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
			border-radius: rem(10px);

			&__cardLayoutIcon {
				>svg {
					width: rem(143px);
					height: rem(86px);
				}
			}

			&__addIconWrap {
				position: absolute;
				top: rem(45px);
				left: rem(44px);
				width: rem(55px);
				height: rem(55px);
				box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);
				border-radius: rem(12px);
				border: rem(1px) solid #d3e6fb;
				background-color: #ffffff;
				display: flex;
				align-items: center;
				justify-content: center;

				&__icon {
					>svg {
						width: rem(25px);
						height: rem(25px);
					}
				}
			}
		}

		&:hover {
			background-color: #f7fbff;
		}
	}
}

.paymentInfoCard {
	position: relative;
	display: inline-flex;
	flex-direction: column;
	width: rem(367px);
	height: rem(220px);
	margin-right: rem(30px);
	margin-bottom: rem(20px);
	border-radius: rem(16px);
	box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05);

	&__headerRow {
		position: absolute;
		top: rem(18px);
		left: 0;
		width: 100%;
		height: rem(35px);
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-left: rem(24px);
		// restore it when the icon will fit width 28px x 35px
		// padding-right: rem(20px);
		&_withCardStatus {
			justify-content: space-between;
		}

		&__cardStatus {
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(84px);
			height: rem(32px);
			border-radius: rem(6px);
			background-color: #ffffff;
			color: #104378;
			font-family: Raleway, sans-serif;
			font-size: rem(14px);
			font-weight: 600;
			letter-spacing: rem(0.14px);
			text-transform: uppercase;

			&_expired {
				color: #ffffff;
				background-image: linear-gradient(to top, #f89728 0%, #f8bc23 100%);
			}
		}

		svg {
			height: rem(35px);
		}
	}

	&__numberRow {
		width: 100%;
		height: rem(23px);
		position: absolute;
		top: rem(114px);
		padding-left: rem(24px);
		padding-right: rem(39px);
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__dots {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: rem(59px);
		}

		&__last4 {
			color: #ffffff;
			font-size: rem(22px);
			font-family: OCRAStd, sans-serif !important;
			font-style: normal;
			font-weight: 400;
		}
	}

	&__footerRow {
		position: absolute;
		top: rem(157px);
		left: 0;
		width: 100%;
		height: rem(39px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: rem(24px);
		padding-right: rem(24px);

		&__expDate {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: rem(130px);
			height: rem(19px);
			color: #ffffff;
			font-family: Raleway, sans-serif;
			letter-spacing: rem(0.16px);
			font-size: rem(16px);

			>span:nth-child(1) {
				font-weight: 500;
			}
			>span:nth-child(2) {
				font-weight: 700;
			}
		}
		&__cardLogo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: rem(68px);
			height: rem(39px);
			border-radius: rem(4px);
			background-color: #ffffff;

			&_amex {
				width: rem(60px);
				height: auto;
			}
		}
	}

	&__removeBtn {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		border-radius: 50%;
		right: rem(-16px);
		top: rem(-16px);
		width: rem(32px);
		height: rem(32px);
		cursor: pointer;
		background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
	}
}

.expiredDate {
	opacity: 0.5;
}


.cardMenu {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	border-radius: 50%;
	right: rem(-16px);
	top: rem(-16px);
	width: rem(32px);
	height: rem(32px);
	cursor: pointer;

	&_active {
		-webkit-animation: rotating 2s linear infinite;
		-moz-animation: rotating 2s linear infinite;
		-ms-animation: rotating 2s linear infinite;
		-o-animation: rotating 2s linear infinite;
		animation: rotating 2s linear infinite;
	}

	&__icon {
		&_opened {
			>svg {
				-webkit-transform: rotate(90deg);
				-moz-transform: rotate(90deg);
				-o-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
			}
		}
	}

	&__contextMenuWrapper {
		position: absolute;
		top: rem(32px);
		right: rem(-2px);
		z-index: 100;
		padding-top: rem(10px);
	}

	&__contextMenu {
		background: #ffffff;
		border-radius: rem(6px);
		border: rem(1px) solid #d3e6fb;
		padding: rem(8px);

		&::before {
			left: auto;
			right: rem(13px);
			position: absolute;
			content: '';
			transform: rotate(45deg);
			width: rem(10px);
			height: rem(10px);
			transition: background 0.1s ease;
			top: rem(5px);
			border-color: #d3e6fb;
			border-style: solid;
			border-width: rem(1px) 0 0 rem(1px);
			z-index: 1001;
			background: #ffffff;
		}

		&__item {
			height: rem(44px);
			width: rem(176px);
			display: flex;
			justify-content: left;
			align-items: center;
			color: #000000;
			font-size: rem(16px);
			font-weight: 500;
			letter-spacing: rem(0.16px);
			padding-left: rem(5px);

			>div {
				margin-left: rem(6px);
				margin-right: rem(10px);
			}

			&:hover {
				border-radius: rem(6px);
				background-color: #e4eefe;
				font-weight: 700;
				cursor: pointer;
			}

			&_remove {
				>div {
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					width: rem(28px);
					height: rem(28px);
					background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
				}
			}
		}
	}
}
/* Safari and Chrome */
@-webkit-keyframes rotating {
	from {
		-webkit-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		transform: rotate(90deg);
	}
	to {
		-webkit-transform: rotate(470deg);
		-o-transform: rotate(470deg);
		transform: rotate(470deg);
	}
}

@keyframes rotating {
	from{
		-ms-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-webkit-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		transform: rotate(90deg);
	}
	to{
		-ms-transform: rotate(470deg);
		-moz-transform: rotate(470deg);
		-webkit-transform: rotate(470deg);
		-o-transform: rotate(470deg);
		transform: rotate(470deg);
	}
}