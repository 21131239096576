@import '~@scss/functions';

$mainBlue: #0C5CE5;
$gradientLight: linear-gradient(180deg, #3871D3 0%, #3A78E1 100%);
$gradientYellow: linear-gradient(to top, #f89728 0%, #f8bc23 100%);
$gradientYellowLight: linear-gradient(to top, #F8AB53 0%, #F8D372 100%);
$gradientYellowDark: linear-gradient(to top, #f87e27 0%, #f8a623 100%);
$hoverGradient: linear-gradient(rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%);
$activeGradient: linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);

$loadingBlockHeight: rem(3px);
$deployBtnHeight: rem(40px);
$gradientBlue: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
$deployBtnIconBlockWidth: rem(40px);
$defaultBtnWidth: rem(196px);
$defaultBtnIconBlockWidth: rem(40px);

.appRequestDropdownButton {
	position: relative;
	display: flex;
	width: $defaultBtnWidth;
	border-radius: rem(6px);
	color: #fff;
	border: none;

	&_disabled {
		opacity: .8;
		pointer-events: none;
		cursor: initial;
	}

	&:not(.appRequestButton_disable):hover,
	&_active:not(.appRequestButton_disable) {
		cursor: pointer;
	}

	&_active:not(.appRequestButton_disable) {

		.appRequestDropdownButton {
			&__btnIconBlock::before {
				opacity: 1;
			}
			&__mainBlock::before {
				opacity: 1;
			}
			&__btnVerticalLine {
				background: #fff;
				z-index: 2;
			}
		}
	}

	&_yellow {
		.appRequestDropdownButton__mainBlock {
			z-index: 1;

			&::before {
				background-image: $gradientBlue;
				border-top-left-radius: rem(6px);
				border-bottom-left-radius: rem(6px);
				position: absolute;
				content: "";
				top: 0;
				right: $defaultBtnIconBlockWidth;
				bottom: 0;
				left: 0;
				z-index: -1;
				transition: opacity 0.3s linear;
				opacity: 0;
			}
		}

		.appRequestDropdownButton__mainBlock:active {
			background-image: $gradientYellowDark;
		}

		.appRequestDropdownButton__btnIconBlock {
			z-index: 1;

			&::before {
				background-image: $gradientBlue;
				border-top-right-radius: rem(6px);
				border-bottom-right-radius: rem(6px);
				position: absolute;
				content: "";
				top: 0;
				right: 0;
				bottom: 0;
				left: $defaultBtnWidth - $defaultBtnIconBlockWidth;
				z-index: -1;
				transition: opacity 0.3s linear;
				opacity: 0;
			}
		}

		.appRequestDropdownButton__mainBlock, .appRequestDropdownButton__btnIconBlock {
			background-image: $gradientYellow;

			&:hover {
				background-image: $gradientYellowLight;
			}

			&:active {
				background-image: $gradientYellowDark;
			}
		}

		.appRequestDropdownButton__btnVerticalLine {
			background: #fad085;
		}
	}

	&__mainBlock {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		width: 100%;
		border-top-left-radius: rem(6px);
		border-bottom-left-radius: rem(6px);
		overflow: hidden;
		background-image: $gradientBlue;

		&:hover {
			background: $mainBlue;
			background-image: $hoverGradient;
		}

		&:active {
			background-image: $activeGradient;
		}
	}

	&__btnText {
		position: absolute;
		top: -($deployBtnHeight + $loadingBlockHeight);
		height: $deployBtnHeight;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: rem(9px) rem(12px) rem(5px);
		font-family: Raleway, sans-serif;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
		white-space: nowrap;
		overflow: hidden;
		visibility: hidden;

		&_visible {
			top: 0;
			visibility: visible;
			transition: top .3s ease-in-out;
		}
	}

	&__btnVerticalLine {
		display: flex;
		height: 100%;
		width: rem(1px);
		background: #749ee7;
	}

	&__btnIconBlock {
		display: flex;
		justify-content: center;
		align-items: center;
		width: $deployBtnIconBlockWidth;
		padding: rem(8px) rem(12px);
		background: #fff;
		background-image: linear-gradient(180deg, #0c5ce5d0 0%, #0852d3d0 100%);
		border-top-right-radius: rem(6px);
		border-bottom-right-radius: rem(6px);

		&:hover {
			background: $mainBlue;
			background-image: $hoverGradient;
		}

		&:active {
			background-image: $activeGradient;
		}
	}

	&__btnIcon {
		display: flex;
		width: $deployBtnIconBlockWidth;
		height: 100%;

		&_loading {
			animation: rotating 1.5s linear infinite;
			>svg {
				width: rem(20px);
				height: rem(20px);
			}
		}

		&_active {
			transform: rotate(180deg);
		}
	}

	&__loadingBlock {
		width: 100%;
		height: $loadingBlockHeight;
		position: relative;
		top: $deployBtnHeight - $loadingBlockHeight;
		background: #749ee7;
		opacity: 0;

		&_visible {
			opacity: 1;
			animation: progressBarApperance 0.5s 1 ease-in;
			-webkit-animation: progressBarApperance 0.5s 1 ease-in;
		}
	}

	&__loadingProgressBar {
		display: flex;
		height: 100%;
		width: 0;
		background: #00A7FF;
		transition: width .4s linear;
	}

	&__optionsBlock {
		position: absolute;
		right: 0;
		top: rem(46px);
		width: rem(204px);
		height: auto;
		border-radius: rem(6px);
		border: rem(1px) solid #d4e2f1;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: rem(6px) 0;

	}

	&__option {
		width: rem(192px);
		height: rem(34px);
		display: flex;
		padding-left: rem(10px);
		align-items: center;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
		line-height: rem(22px);
		color: #ff3232;

		&:hover {
			border-radius: rem(4px);
			background-color: #fff3f3;
			cursor: pointer;
		}
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes progressBarApperance {
	0% {
		opacity: 0;
	}
	10% {
		opacity: .1;
	}
	20% {
		opacity: .2;
	}
	30% {
		opacity: .3;
	}
	40% {
		opacity: .4;
	}
	50% {
		opacity: .5;
	}
	60% {
		opacity: .6;
	}
	70% {
		opacity: .7;
	}
	80% {
		opacity: .8;
	}
	90% {
		opacity: .9;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes progressBarApperance {
	0% {
		opacity: 0;
	}
	10% {
		opacity: .1;
	}
	20% {
		opacity: .2;
	}
	30% {
		opacity: .3;
	}
	40% {
		opacity: .4;
	}
	50% {
		opacity: .5;
	}
	60% {
		opacity: .6;
	}
	70% {
		opacity: .7;
	}
	80% {
		opacity: .8;
	}
	90% {
		opacity: .9;
	}
	100% {
		opacity: 1;
	}
}