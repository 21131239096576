@import '~@scss/functions';

.popup {
	padding: rem(8px) !important;
	left: rem(12px) !important;
}

.nodeOptionsList {
	box-sizing: border-box;
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
}

.nodeOptionItem {
	display: flex;
	padding: rem(8px);
	box-sizing: border-box;
	background-color: #fff;
	transition: background-color 0.2s ease;
	border-radius: rem(6px);
	align-items: center;
	cursor: pointer;

	&__icon {
		width: rem(26px);
		height: rem(26px);
		border-radius: 50%;
		margin-right: rem(10px);
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);

		&_danger {
			background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
		}
	}

	.title {
		line-height: 1;
		font-weight: 400;
		transition: font-weight all 0.2s ease;
		letter-spacing: rem(0.16px);

		&__danger {
			color: #ff3232;
		}

		&:after { // hack to prevent parent block size stretching on font-weight change
			display: block;
			content: attr(title);
			font-weight: 600;
			height: rem(1px);
			overflow: hidden;
			color: transparent;
			visibility: hidden;
		}
	}

	&:hover {
		background-color: #e4eefe;

		.title {
			font-weight: 600;
		}
	}
}

.trigger {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: rem(20px);
}