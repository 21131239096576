@import '~@scss/functions';

$defaultSelectHeight: rem(32px);
$white: #FFFFFF;
$defaultSelectItemHeight: rem(34px);

.selectWithSearch {
	display: flex;
	align-items: center;
	cursor: pointer;

	&.disabled {
		opacity: 0.7;
	}

	&__label {
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__select {
		display: flex;
		align-items: center;
		min-width: rem(100px);
		min-height: rem(32px);
		position: relative;
		border-radius: rem(6px);
		border: rem(1px) solid #b9cee4;
		background-color: #ffffff;
		background-position: 92% 50%;
		color: #000000;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05);
		transition: all 240ms ease;

		&_active {
			color: #000000;
			box-shadow: 0 rem(3px) rem(6px) rgba(12, 92, 229, 0.2);
			border: rem(1px) solid #0C5CE5;
			outline: none;
		}

		&_error {
			color: #000000;
			box-shadow: 0 rem(3px) rem(6px) rgba(255, 50, 50, 0.2);
			border: rem(1px) solid #ff3232;
			outline: none;
		}

		>span {
			>svg {
				position: absolute;
				right: rem(17px);
			}
		}

		&__input, &__fakeInput {
			font-family: Raleway, sans-serif;
			cursor: pointer;
			width: 100%;
			height: 100%;
			padding-left: rem(11px);
			padding-right: rem(16px);
			background-color: transparent;
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
		}

		&__fakeInput {
			display: flex;
			align-items: center;
			border-radius: rem(6px);

			&_disabled {
				background: #fafdff;
				color: rgba(0, 0, 0, 0.4);
				border: rem(1px) solid #b9cee4;
				cursor: not-allowed;
			}
		}

		&__input {
			border:none;
			outline: none;
		}

		&__options {
			top: $defaultSelectHeight;
			background: $white;
			z-index: 1000;
			position: absolute;
			margin-top: rem(6px);
			border: rem(1px) solid #d4e2f1;
			border-radius: rem(6px);
			overflow: hidden !important;

			>div {
				padding-top: rem(6px);
				padding-bottom: rem(6px);
				overflow: auto;
			}

			&_top {
				top: -$defaultSelectHeight;
			}

			&__item {
				min-width: rem(100px);
				min-height: rem(32px);
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-right: auto;
				margin-left: auto;
				margin-top: rem(2px);
				border-radius: rem(4px);
				padding-top: rem(9px);
				padding-right: rem(6px);
				padding-bottom: rem(9px);
				padding-left: rem(10px);
				cursor: pointer;

				&:hover:not(.selectWithSearch__optionSelectedWithoutSign) {
					background-color: #f7fbff;
				}

				&:active, &:focus{
					font-size: rem(16px);
					font-weight: 400;
					letter-spacing: rem(0.16px);
					line-height: rem(22.16px);
				}
			}
		}
	}

	&__selectedIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(22px);
		height: rem(22px);
		margin-right: rem(10px);
		border-radius: 50%;
		background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);

		>span {
			>svg {
				width: rem(12px);
				height: rem(12px);
			}
		}
	}

	&__optionSelectedWithoutSign {
		background-color: #f7fbff;
	}
}