@import '~@scss/functions';

.estimatedCostTable {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-radius: rem(6px);
	border: rem(1px) solid #d4e2f1;
	background-color: #ffffff;
	position: relative;

	&__header {
		display: flex;
		align-items: center;
		height: rem(60px);
		padding: 0 rem(16px);
	}

	&__headerTitle {
		color: #000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__skeleton {
		width: rem(64px);
		margin: rem(40px) auto 0 auto;
		position: relative;
		top: rem(-24px);
	}

	&__content {
		display: flex;
		flex: 2 1 rem(60px);
		border-top: rem(1px) solid #d4e2f1;
	}

	&__col {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 33.3%;
		padding: rem(45px) rem(38px);
		text-align: center;
	}

	&__col:not(:last-child) {
		border-right: rem(1px) solid #d4e2f1;
	}

	&__colLabel {
		color: #545a76;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(1.5px);
		line-height: rem(22px);
		text-transform: uppercase;
	}

	&__colValue {
		color: #050d29;
		font-family: Raleway, sans-serif;
		font-size: rem(28px);
		font-weight: 700;
		line-height: rem(40px);
	}
}