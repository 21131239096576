@import '~@scss/functions';

.basicInput {
	position: relative;
	width: 100%;
	display: flex;

	&__skeleton {
		width: rem(128px);
		position: absolute;
		z-index: 1;
		margin-top: rem(19.2px);
		margin-left: rem(16px);
	}

	&__input {
		width: 100%;
		>input {
			background: #ffffff !important;
			color: #000;
			border: rem(1px) solid #b9cee4 !important;
			box-shadow: 0 rem(1px) rem(2px) rgba(0, 0, 0, 0.05) !important;
			&:active, &:focus {
				box-shadow: 0 rem(3px) rem(6px) rgba(12, 92, 229, 0.2) !important;
				border: rem(1px) solid #0C5CE5 !important;
				outline: none !important;
			}
		}

		&_withError {
			box-sizing: border-box;
			input {
				border: rem(1px) solid #ff3232 !important;
				box-shadow: 0 rem(3px) rem(6px) rgba(255, 50, 50, 0.2) !important;
				display: block;
				padding-right: rem(44px) !important;
				background-color: #fff !important;
				box-sizing: border-box !important;

				&:active, &:focus {
					box-shadow: 0 rem(3px) rem(6px) rgba(255, 50, 50, 0.2) !important;
					border: rem(1px) solid #ff3232 !important;
					outline: none !important;
				}
			}
		}

		&_disabled {
			cursor: not-allowed;

			>input {
				background: #fafdff !important;
				color: rgba(0, 0, 0, 0.4) !important;
				border: rem(1px) solid #b9cee4 !important;
			}
		}
	}

	.errorHint {
		width: rem(32px);
		height: rem(32px);
		position: absolute;
		right: rem(6px);
		align-self: center;
	}

	:global(.ui.disabled.input) {
		opacity: 1 !important;
		cursor: not-allowed;
		>input {
			background: #fafdff !important;
			color: rgba(0, 0, 0, 0.4) !important;
			border: rem(1px) solid #b9cee4 !important;
		}
	}
}