@import '~@scss/functions';

.buySeats {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: rem(367px);
	min-height: rem(560px);
	border-radius: rem(16px);
	background-color: #ffffff;

	&__icon {
		position: absolute;
		top: rem(-43px);
		left: rem(141px);
	}

	&__close {
		position: absolute;
		right: 0;
		top: rem(-34px);
		cursor: pointer;
		width: rem(20px);
		height: rem(20px);

		.hidden {
			pointer-events: none;
			visibility: hidden;
		}
	}

	&__header {
		margin-top: rem(59px);
		height: rem(19px);
		line-height: rem(19px);
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__description {
		margin-top: rem(5px);
		width: rem(317px);
		height: rem(56px);
		text-align: center;
		opacity: 0.5;
		color: #000000;
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
		line-height: rem(20px);
	}


	&__seatsError, &__infoPanel {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: rem(24px);
		width: rem(317px);
		height: rem(38px);
		border-radius: rem(6px);
		color: #000000;
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
	}

	&__infoPanel {
		border: rem(1px) solid #d4e2f1;
		background-color: #f8fcfe;
	}

	&__seatsError {
		height: auto;
		padding: rem(11px) rem(10px) rem(10px);
		border: rem(1px) solid #ff3232;
		background-color: #fff3f3;
	}

	&__addSeats {
		margin-top: rem(20px);
		width: rem(317px);
		height: rem(46px);
		border-radius: rem(6px);
		border: rem(1px) solid #d3e6fb;
		background-color: #ffffff;
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 rem(7px) 0 rem(14px);

		&__counter {
			display: flex;
			width: rem(96px);
			height: rem(32px);
			border-top: rem(1px) solid #d3e6fb;
			border-bottom: rem(1px) solid #d3e6fb;
			border-radius: rem(6px);

			>div {
				width: rem(32px);
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&__dec {
				background: #f7fbff;
				border-left: rem(1px) solid #d3e6fb;
				border-radius: rem(6px) 0 0 rem(6px);
				border-right: rem(1px) solid #d3e6fb;
				cursor: pointer;
			}
			&__inc {
				background: #f7fbff;
				border-right: rem(1px) solid #d3e6fb;
				border-radius: 0 rem(6px) rem(6px) 0;
				border-left: rem(1px) solid #d3e6fb;
				cursor: pointer;
			}
		}
	}

	&__subHeader {
		margin-top: rem(20px);
		width: rem(317px);
		height: rem(19px);
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__summaryTable {
		margin-top: rem(16px);
		width: rem(317px);
		height: rem(138px);
		border-left: rem(1px) solid #d4e2f1;
		border-right: rem(1px) solid #d4e2f1;
		margin-bottom: rem(40px);
		border-radius: rem(6px);

		>div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 rem(14px);
			height: rem(46px);
			color: #000000;
			font-size: rem(16px);
			font-weight: 600;
			letter-spacing: rem(0.16px);
			border-bottom: rem(1px) solid #d4e2f1;

			&:nth-child(1) {
				border-top: rem(1px) solid #d4e2f1;
				border-radius: rem(6px) rem(6px) 0 0;
			}

			&:nth-last-child(1) {
				border-radius: 0 0 rem(6px) rem(6px);
			}

		}
		&__totalDue {
			background: #f8fcfe;
			>span:nth-child(2) {
				color: #0c5ce5;
			}
		}
	}

	&__addSeatsBtn, &__rmSeatsBtn {
		height: rem(40px);
	}

	&__addSeatsBtn {
		width: rem(140px);
	}

	&__rmSeatsBtn {
		width: rem(141px);
	}

	>form {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: rem(20px);
	}
}