@import '~@scss/functions';

.userAvatar {
	&__img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: #e4eefe;
		background-image: linear-gradient(90deg, #e4eefe 0, rgba(255, 255, 255, 0.6) rem(40px), #e4eefe rem(80px));
	}
}