@import '~@scss/functions';

.mapWrapper {
	position: relative;
	overflow: hidden;
	width: calc(100% + 40px);
	height: rem(360px) !important;
	margin: rem(-20px) 0 0 rem(-20px);
}

.mapWrapper .point {
	position: absolute;
}

.mapContainer {
	width: 100%;
	margin-top: -8%;
	position: relative;

	&__zoom {
		width: 115%;
		margin-left: -12%;
	}
}

.img {
	width: 100%;
	position: relative;

	svg{
		width: 100%;
		height: auto;
	}
}