@import '~@scss/functions';

.clusterNodes {
	&__pageHead {
		position: relative;
	}
	&__errorMessage {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		top: rem(5px);
		bottom: auto;
		width: auto;
		max-height: rem(78px);
	}
}