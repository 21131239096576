@import '~@scss/functions';

.clusterUsageCard {
	display: flex;
	position: relative;
	height: 100%;

	&__skeleton {
		width: rem(22px);
		margin-top: rem(34px);
		position: relative;
		top: rem(-16px);
	}

	&__item {
		height: 100%;
		display: flex;
		padding: 0 !important;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.title {
			text-transform: uppercase;
			color: #545a76;
			letter-spacing: rem(1px);
			line-height: rem(22px);
		}

		.value {
			text-transform: uppercase;
			font-weight: 700;
			color: #050d29;
			line-height: rem(40px);
			font-size: rem(28px);
		}
	}
}