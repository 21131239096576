@import '~@scss/functions';

.regionBar {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: rem(46px);
	justify-content: space-between;
	padding: 0 rem(12px);
	box-sizing: border-box;
	background-color: #fff;
	border-radius: rem(6px);
	border: rem(1px) solid #d3e6fb;

	.labelWrapper {
		display: flex;
		align-items: center;
		height: 100%;
		.flagIcon {
			width: rem(28px);
			height: rem(20px);
			line-height: rem(28px);
			margin-right: rem(8px);
		}

		.label {
			text-transform: uppercase;
			font-weight: 600;
			font-size: rem(16px);
			color: #000;
		}
	}
}