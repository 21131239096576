@import '~@scss/functions';

$defaultBorderRadius: rem(6px);
$darkBlue: #104378;

.clusterLoad {
	display: flex;
	box-sizing: content-box;
	width: rem(112px);
	align-items: center;
	justify-content: center;
	align-content: stretch;
	cursor: pointer;
	position: relative;

	&__metrics {
		width: rem(29px);
		margin-right: rem(8px);
		color: #000;
		font-size: rem(14px);
		font-weight: 500;
		letter-spacing: rem(0.14px);
	}

	&__progress {
		flex-grow: 1;
	}

	&__descr {
		border-radius: $defaultBorderRadius !important;
		border: rem(1px) solid #d3e6fb !important;
		background-color: #ffffff !important;
		top: rem(20px);
		left: rem(-10px);
		position: absolute !important;
		color: #000000;
		font-size: rem(16px) !important;
		font-weight: 400 !important;
		letter-spacing: rem(0.16px) !important;
		z-index: 1000;
		width: rem(200px);

		&::before {
			left: 9% !important;
		}

		&__row {
			margin-bottom: rem(10px);
			width: rem(186px);
			height: rem(24px);

			&:last-child {
				margin-bottom: 0;
			}
			>span {
				display: inline-block;
				height: 100%;
				line-height: rem(24px);
			}

			&__metrics {
				height: 100%;
				float: right;

				div:first-child, div:last-child {
					display: table-cell;
					text-align: center;
					vertical-align: middle;
					padding: rem(3px) 0;
					width: rem(38px);
					height: 100%;
					border: rem(1px) solid #dae6f2;
					font-size: rem(16px);
					font-weight: 500;
					letter-spacing: rem(0.16px);
					line-height: rem(22px);
					color: $darkBlue;
				}

				div:first-child {
					border-radius: rem(4px) 0 0 rem(4px);
					background-color: #ffffff;
				}
				div:last-child {
					border-radius: 0 rem(4px) rem(4px) 0;
					background-color: #f7fbff;
					opacity: 0.7;
				}
			}
		}
	}
}
