@import '~@scss/functions';

$black: #000;
$white: #fff;


@media (max-width: 768px) {
	.resetPass {
		width: rem(375px) !important;
		margin-left: auto;
		margin-right: auto;

		&__header {
			height: auto !important;
			padding: rem(24px) rem(20px) 0 rem(16px) !important;
			margin-bottom: rem(32px) !important;
			// logo
			>div {
				margin-top: rem(6px) !important;
			}
			// hide create accbtn
			>button {
				display: none !important;
			}
		}

		&__content {
			flex-direction: column !important;
			align-items: center !important;
			padding-top: 0 !important;
			&__logo {
				margin: 0 0 rem(32px) !important;
				>div {
					width: rem(169px) !important;
					height: rem(209px) !important;
				}
			}

			&__form {
				width: rem(343px) !important;
				.logoAppfleet {
					display: none !important;
				}

				&__subheader {
					margin-bottom: rem(41px) !important;
				}

				>form {
					>div:nth-child(1) {
						margin-bottom: rem(40px) !important;
						>label {
							width: rem(343px) !important;
							flex-direction: column !important;
							align-items: flex-start !important;
							>input {
								width: rem(343px) !important;
							}

							// error icon
							>span {
								bottom: rem(7px) !important;
								right: rem(7px) !important;
							}

							// error text
							>div:nth-last-child(1) {
								left: 0 !important;
								bottom: rem(-20px) !important;
								top: auto !important;
							}
						}
					}
				}

				&__ctrls {
					width: rem(343px) !important;
					margin-bottom: rem(24px) !important;
					>button:nth-child(1) {
						width: rem(149px) !important;
						height: rem(40px) !important;
					}
					>button:nth-child(2) {
						width: rem(180px) !important;
						height: rem(40px) !important;
					}
				}

				&__error {
					margin-top: rem(32px) !important;
				}
				&__createAccBtn_mobile {
					display: block !important;
					text-align: center;
					text-decoration: underline;
					color: #000000;
					margin-bottom: rem(32px);
					font-size: rem(16px);
					font-weight: 400;
					font-style: normal;
					letter-spacing: rem(0.16px);
				}
			}
		}
	}
}

@media screen and (min-width: 1920px) {
	.resetPass {
		width: rem(1360px) !important;
	}
}

.resetPass {
	color: $black;
	height: 100%;
	margin-left: auto;
	margin-right: auto;

	&__header {
		margin-bottom: 0;
	}

	.btnCreateAccount {
		width: rem(175px);
		height: rem(40px);
		color: $white;
		font-size: rem(16px);
		font-weight: 700;
		letter-spacing: rem(0.16px);
	}

	&__content {
		display: flex;
		align-items: flex-start;
		justify-content: center;

		&__logo {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
			max-width: rem(645px);
			margin-right: rem(105px);
			margin-top: rem(48px);

			>div:first-child {
				width: rem(325px);
				height: rem(400px);
				background: url("../../../../assets/images/logo.reset.png") no-repeat;
				background-size: contain;
				position: relative;
			}
		}

		&__form {
			flex-grow: 1;

			>.logoAppfleet {
				margin-bottom: rem(60px);
				position: relative;
				width: rem(248px);
				height: rem(48px);
			}

			&__header {
				color: $black;
				height: rem(29px);
				font-size: rem(24px);
				font-weight: 600;
				letter-spacing: rem(0.24px);
				margin-bottom: rem(8px);
			}
			&__subheader {
				color: $black;
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(0.16px);
				line-height: rem(22px);
				margin-bottom: rem(60px);
			}

			&__ctrls {
				display: flex;
				justify-content: space-between;
				width: rem(566px);

				.btnResetPass {
					width: rem(184px);
					height: rem(40px);
					font-size: rem(16px);
					font-weight: 700;
					letter-spacing: rem(0.16px);
				}

				.btnBackToLogin {
					width: rem(125px);
					height: rem(40px);
					font-size: rem(16px);
					font-weight: 700;
					letter-spacing: rem(0.16px);
				}
			}

			&__error {
				margin-top: rem(40px);
				width: rem(566px);
				height: rem(77px);
			}
			&__createAccBtn_mobile {
				display: none;
			}
		}
	}

	&__emptyBlock {
		display: flex;
		width: 100%;
		height: rem(85px);
	}
}