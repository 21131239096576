@import '~@scss/functions';

.billingWizard {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	height: 100%;


	.headerContentWrapper {
		width: 100%;
		background-color: #fff;
		display: flex;
		justify-content: center;
	}

	.header {
		display: flex;
		padding: 0 rem(40px);
		justify-content: space-between;
		height: rem(70px);
		max-height: rem(70px);
		background-color: #fff;
		align-items: center;
		box-sizing: border-box;
		flex: 1;

		.headerLogo {

			.brandIcon {
				width: rem(41px);
			}
		}

		.headerContent {
			max-width: rem(708px);
			width: 100%;
		}
	}

	.content {
		flex: 2;
		width: 100%;
		background-image: linear-gradient(180deg, #f4faff 0%, #ffffff 800px, #fefefe 801px, #fefefe 100%);
	}

	.progressBar {
		height: "16px";

		.dot {
			position: relative;
			display: block;
			z-index: 5;
			width: rem(8px);
			height: rem(8px);
			border-radius: 50%;
			transition: all 0.3s ease-out;
			background-color: #0c5ce5;

			&__not_reached {
				opacity: 0.15;
			}

			&__passed {
				opacity: 0.26;
				background-color: #ffffff;
			}

			&__active {
				width: rem(16px);
				height: rem(16px);
				opacity: 1;
			}
		}
	}
}

.tooltip {
	opacity: 0.75 !important;
	border-radius: rem(6px) !important;
	font-family: Raleway, sans-serif !important;
	font-size: rem(14px) !important;
	font-weight: 400 !important;
	font-style: normal !important;
	letter-spacing: rem(0.14px) !important;
	line-height: normal !important;
	text-align: left !important;
	padding: rem(11px) rem(15px) !important;
}