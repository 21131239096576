@import '~@scss/functions';

.team {
	width: rem(1161px);
	margin-left: auto;
	margin-right: auto;

	&__head {
		display: flex;
		justify-content: space-between;
		margin-top: rem(57px);
		margin-bottom: rem(57px);
		height: rem(47px);

		&__header {
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
		}
		&__search {
			padding: 0;
			margin: 0;
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);
		}
	}

	&__utilityTables {
		display: flex;

		&__teamStatus {
			display: flex;
			flex-direction: column;
			width: rem(566px);
			height: rem(215px);
			border-radius: rem(6px);
			border: rem(1px) solid #d4e2f1;
			background-color: #ffffff;
			margin-right: rem(29px);
			position: relative;

			&__skeleton {
				width: rem(22px);
				top: rem(-16px);
				position: relative;
				margin-top: rem(36px);
			}

			&__head {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: rem(60px);
				padding: 0 rem(14px) 0 rem(20px);

				>span:nth-child(1) {
					color: #000000;
					font-size: rem(16px);
					font-weight: 600;
					letter-spacing: rem(0.16px);
				}
			}

			&__body {
				display: flex;
				justify-content: space-between;
				border-top: rem(1px) solid #d4e2f1;

				>div {
					display: flex;
					flex-grow: 1;
					flex-shrink: 1;
					flex-basis: 0;
					height: rem(154px);
					flex-direction: column;
					justify-content: center;
					align-items: center;

					>div:nth-child(1) {
						color: #545a76;
						font-size: rem(16px);
						font-weight: 400;
						letter-spacing: rem(1.5px);
						line-height: rem(22px);
						margin-bottom: rem(2px);
					}
					>div:nth-child(2) {
						color: #050d29;
						font-size: rem(28px);
						font-weight: 700;
						line-height: rem(40px);
					}
				}

				&__members {
					border-right: rem(1px) solid #d4e2f1;
				}

				&__available {
					border-right: rem(1px) solid #d4e2f1;
					>div:nth-child(1) {
						display: flex;
					}

					&__tooltipLabel {
						height: rem(43px);
						width: rem(275px);
						font-size: rem(14px);
						font-weight: 400;
						letter-spacing: rem(0.14px);
					}
				}
			}

			&__warning {
				&__btn {
					&_agree {
						display: flex !important;
						width: auto !important;
						padding: rem(16px) !important;
						align-items: center;
					}
				}
			}
		}

		&__inviteMembers {
			display: flex;
			flex-direction: column;
			width: rem(566px);
			height: rem(215px);
			border-radius: rem(6px);
			border: rem(1px) solid #d4e2f1;

			&__head {
				height: rem(60px);
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0 rem(16px) 0 rem(20px);
				font-size: rem(16px);
				font-weight: 600;
				letter-spacing: rem(0.16px);
				color: #000000;
				border-bottom: rem(1px) solid #d4e2f1;
			}

			&__body {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				&__subHeader {
					color: #000000;
					font-size: rem(16px);
					font-weight: 400;
					letter-spacing: rem(0.16px);
					width: rem(526px);
					text-align: center;
				}
			}
		}
	}

	&__table {
		margin-top: rem(60px);

		&__loadingRow {
			> div {
				height: rem(228px);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
			}

			&__icon {
				height: rem(66px);
			}

			&__label {
				letter-spacing: rem(0.16px);
				font-weight: 600;
				padding-top: rem(16px);
				margin: 0 auto;
			}
		}
	}

	&__btnWithSvg {
		display: flex !important;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;
		padding-left: rem(6px) !important;
	}
}

.btnArrow {
	font-size: rem(14px) !important;
	font-weight: 600 !important;
	letter-spacing: rem(0.14px) !important;
	width: rem(182px);
	height: rem(32px);
	padding: 0;
	margin-right: 0;

	>span {
		margin-right: rem(10px);
	}
}

.inviteMemberTableBtn {
	width: rem(150px);
	height: rem(32px);
	color: #104378;
	font-size: rem(14px) !important;
	font-weight: 600 !important;
	letter-spacing: rem(0.14px) !important;

	>span {
		margin-right: rem(11px);
	}
}

.angleRightDark{
	width: rem(8px);
	height: rem(12px);
}