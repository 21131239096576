@import '~@scss/functions';

.titleContainer {
	display: flex;
	width: 100%;
	padding-right: rem(16px);
	position: relative;
	height: rem(160px);
	align-items: center;

	h1,h2 {
		margin: 0;
	}

	&__imageWrapper {
		width: rem(80px);
		height: rem(80px);
		margin-right: rem(26px);

		>img, >div {
			width: rem(80px);
			height: rem(80px);
		}

		&_placeholder {
			border-radius: rem(22px);
			background-color: #eaf6ff;
		}
	}

	&__textContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		padding: rem(4px) 0;
		height: rem(80px);

		&_title {
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
			position: relative;

			&_skeleton {
				height: rem(32px) !important;
				width: rem(200px);
				margin-top: rem(6px);
			}
		}

		&_subtitle {
			position: relative;
			line-height: rem(22px);
			color: #545a76;
			font-weight: 400;
			letter-spacing: rem(0.16px);
			font-size: rem(16px);

			&_skeleton {
				height: rem(16px) !important;
				margin-right: rem(40px);
			}
		}
	}

	&__errorMessage {
		position: absolute;
		left: 60%;
		transform: translate(-50%, 0);
		bottom: auto;
	}
}