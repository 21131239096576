@import '~@scss/functions';

.addPerms {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: rem(404px);
	height: rem(527px);
	border-radius: rem(16px);
	background-color: #ffffff;

	&__close {
		position: absolute;
		right: 0;
		top: rem(-34px);
		cursor: pointer;
		width: rem(20px);
		height: rem(20px);
	}

	&__icon {
		position: absolute;

		top: rem(-43px);
		left: rem(159px);
		width: rem(86px);
		height: rem(86px);
	}

	&__header {
		margin-top: rem(59px);
		width: rem(168px);
		height: rem(19px);
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
	}

	&__subHeader {
		margin-top: rem(5px);
		text-align: center;
		width: rem(300px);
		height: rem(36px);
		opacity: 0.5;
		color: #000000;
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
		line-height: rem(20px);
	}

	&__tableWrap {
		position: relative;
		margin-top: rem(29px);
		margin-bottom: rem(40px);
		width: rem(354px);
		height: rem(280px);
		background-color: #ffffff;
		border: rem(1px) solid #d4e2f1;
		border-radius: rem(15px);

		&__table {
			display: flex;
			width: rem(354px);
			flex-direction: column;

			&__row {
				position: relative;
				display: flex;
				align-items: center;
				padding-left: rem(20px);
				padding-right: rem(20px);
				width: 100%;
				height: rem(56px);
				border-bottom: rem(1px) solid #d4e2f1;
				&:nth-last-child(1) {
					border-bottom: none;
				}

				&__avatar {
					width: rem(36px);
					min-width: rem(36px);
					height: rem(36px);
					min-height: rem(36px);
					margin-right: rem(16px);
				}
				&__avatarPlaceholder {
					width: rem(36px);
					height: rem(36px);
					border-radius: rem(36px);
					background-color: #eaf6ff;
					margin-right: rem(16px);
				}
				>span {
					color: #000000;
					font-size: rem(16px);
					font-weight: 400;
					letter-spacing: rem(0.16px);
				}
				&__ctrl {
					position: absolute;
					right: rem(20px);
					width: rem(20px);
					height: rem(20px);
					cursor: pointer;
				}
				&__addMembers {
					font-size: rem(14px) !important;
					font-weight: 400 !important;
					letter-spacing: rem(0.14px) !important;
					line-height: rem(20px) !important;
					max-width: rem(250px);
					margin: auto;
				}
			}
		}

		&__shadowBlock {
			position: absolute;
			bottom: rem(-1px);
			left: 0;
			width: 100%;
			height: rem(56px);
			background-image: linear-gradient(180deg, transparent 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 100%);
		}
	}

	&__saveBtn {
		width: rem(140px);
		height: rem(40px);
	}
}
