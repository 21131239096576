@import '~@scss/functions';

.clustersList {
	width: rem(1200px);
	margin-left: auto;
	margin-right: auto;

	&__head {
		display: flex;
		justify-content: space-between;
		margin-top: rem(57px);
		margin-bottom: rem(60px);
		height: rem(47px);

		&__header {
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
		}
		&__search {
			padding: 0;
			margin: 0;
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);
		}
	}

	&__clustersTable {
		position: relative;
	}

	.loadingRow {
		> div {
			height: rem(228px);
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
		}

		&__icon {
			height: rem(66px);
		}

		&__label {
			letter-spacing: rem(0.16px);
			font-weight: 600;
			padding-top: rem(16px);
			margin: 0 auto;
		}
	}

	.spinner {
		width: rem(60px);
		height: rem(60px);
		margin: 0 auto;
	}

	&__lastRow {
		> td {
			border-bottom: rem(1px) solid rgb(212, 226, 241) !important;
		}
	}

	&__footerTableBtn {
		display: flex !important;
		flex-wrap: nowrap !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 0 rem(6px) !important;

		&>span {
			margin-right: 0 !important;
		}
	}

	&__addBillingInfoBanner {
		&_withMargin {
			margin-bottom: rem(6px);
		}
	}
}
