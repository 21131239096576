@import '~@scss/functions';

.billingPromoInfo {
	width: rem(565px);
	height: rem(259px);
	margin: 0 auto;
	border-radius: rem(6px);
	border: rem(1px) solid #d4e2f1;
	background-color: #ffffff;

	&__promoInputBlock {
		display: flex;
		justify-content: center;
		align-items: center;
		height: rem(86px);
		border-bottom: rem(1px) solid #d4e2f1;
		padding-left: rem(20px);
		padding-right: rem(20px);
	}

	&__promoInput {
		width: 100%;
	}

	&__contentBlock {
		position: relative;
		display: flex;
		height: rem(170px);
		width: rem(562px);
	}


	&__bgElemLeft, &__bgElemRight {
		position: absolute;
	}
	&__bgElemLeft {
		top: 0;
		left: 0;
	}
	&__bgElemRight {
		top: rem(31px);
		right: rem(-1px);
	}

	&__otpBlock {
		display: flex;
		flex-direction: column;
		height: rem(95px);
		border-top: rem(1px) solid #d4e2f1;
		border-right: rem(1px) solid #d4e2f1;
		border-bottom: rem(1px) solid #d4e2f1;
		border-radius: rem(2px);
		position: relative;
		margin-top: rem(39px);

		&__requestIcon {
			>svg {
				width: rem(30px);
				height: rem(30px);
				position: absolute;
				right: rem(-15px);
				bottom: rem(-10px);
			}
		}

		&__otpHeader {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: rem(38px);
			width: rem(138px);
			border-bottom: rem(1px) solid #d4e2f1;

			&__otpHeaderPolygon {
				width: rem(50px);
				height: rem(6px);
				border-radius: rem(2px);
				background-color: #104378;
				opacity: 0.1;
			}

			&__otpHeaderRight {
				display: flex;
				align-items: center;
				justify-content: center;
				width: rem(45px);
				height: rem(17px);
				margin-left: rem(33px);
				background-color: rgba(12, 92, 229, 0.2);
				border-radius: rem(4px);

				>div {
					width: rem(29px);
					height: rem(3px);
					border-radius: rem(2px);
					background-color: #0c5ce5;
				}
			}
		}

		&__otpContnet {
			display: flex;
			height: rem(57px);

			&__otpContentEmptyBlock {
				display: flex;
				width: rem(10px);
				height: auto;
				border-right: rem(1px) solid #d4e2f1;
			}

			&__otpContentSymbol {
				display: flex;
				align-items: center;
				justify-content: center;
				width: rem(42px);
				height: rem(56px);
				color: #000;
				font-size: rem(18px);
				font-weight: 700;
				letter-spacing: rem(0.18px);
				text-transform: uppercase;

				&:not(:last-child){
					border-right: rem(1px) solid #d4e2f1;
				}
			}

			&__couponCredit {
				color: #0c5ce5;
				font-size: rem(20px);
				font-weight: 600;
				letter-spacing: rem(0.2px);
				width: 100%;
				height: rem(57px);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	&__textBlock {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin-left: rem(40px);
		margin-right: rem(30px);
		margin-top: rem(49px);

		&__title {
			display: flex;
			font-size: rem(16px);
			font-weight: 600;
			letter-spacing: rem(0.16px);
			margin-bottom: rem(10px);
		}

		&__message {
			display: flex;
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);
			color: #000;
		}
	}
}