@import '~@scss/functions';

.stepLayoutWithLogo {
	max-width: rem(1200px);
	width: 100%;
	height: 100%;
	margin: 0 auto;
	box-sizing: border-box;
	padding: rem(80px) rem(16px) 0 rem(16px);

	&_wrapper {
		height: 100%;
		flex-direction: column !important;
	}

	&__controlsRow {
		padding: rem(32px) 0;
		width: 100%;
		max-width: rem(963px);
	}
}

@media screen and (min-height: 1200px) {
	.stepLayoutWithLogo {
		&_wrapper {
			flex: 1;
		}

		&__controlsRow {
			margin-top: rem(32px);
			flex: 2;
		}
	}
}

.imageRow {
	max-height: rem(400px);
}

.imageCol,.contentCol {
	height: 100%;
}

.imageCol {
	text-align: center;
}

.contentWrapper {
	flex-direction: column !important;
	flex-wrap: nowrap !important;
	height: 100%;

	.header, .content {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		text-align: center;

		.titles {
			span:first-child {
				font-size: rem(32px);
				font-weight: 700;
				line-height: rem(40px);
			}

			span:last-child {
				max-width: rem(600px);
				margin: 0 auto;
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(0.16px);
				line-height: rem(22px);
			}
		}
	}

	.content {
		height: 100%;
	}
}