@import '~@scss/functions';

.noDomains {
	display: flex;
	flex-direction: column;
	justify-content: center;

	&__icon {
		margin-bottom: rem(16px);
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #000000;
		letter-spacing: rem(0.16px);
		line-height: rem(19px);

		&__header {
			font-size: rem(16px);
			font-weight: 600;
		}

		&__msg {
			margin-top: rem(8px);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #545a76;
			font-size: rem(14px);
			font-weight: 500;
		}
	}
}