@import '~@scss/functions';

.logoMain {
	height: rem(28px);
	width: rem(41px);
	cursor: pointer;
}

.pageHeader{
	height: rem(70px);
	display: flex;
	justify-content: space-between;
	padding: rem(15px) rem(40px);
	margin-bottom: rem(40px);
}
