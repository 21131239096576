@import '~@scss/functions';

.banner {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: rem(16px);

	background-color: #fff3f3;
	border-radius: rem(6px);
	border: rem(1px) solid #ff3232;

	&__title {
		margin-bottom: rem(10px);
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__message {
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
		color: #000;
	}

	&__textImgBlock {
		margin-right: rem(16px);
	}

	&__textImg {
		display: flex;
		width: rem(12px);
		height: rem(12px);
		padding: rem(4px);
		background-color: #fff;
		border: rem(8px) solid #ff4517;
		border-radius: 50%;
		box-sizing: content-box;
	}
}