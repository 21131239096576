@import '~@scss/functions';

.tooltip {
	display: flex;
	z-index: 1000;
	position: absolute;
	color: #FFFFFF;
	background: rgba(0,0,0,0.75);
	border-radius: rem(6px);
	font-weight: 400;
	font-size: rem(14px);
	letter-spacing: rem(0.14px);
	padding: rem(8px) rem(15px);
	white-space: nowrap;
	bottom: rem(40px);
	left: rem(-10px);

	&::before {
		left: calc(50% - 6px);
		right: auto;
		position: absolute;
		content: '';
		width: rem(10px);
		height: rem(6px);
		transition: background 0.1s ease;
		bottom: rem(-9px);
		border-left: solid transparent rem(10px);
		border-right: solid transparent rem(10px);
		border-top: solid rgba(0,0,0,0.75) rem(10px);
		z-index: 1001;
	}
}