@import '~@scss/functions';

.appButton {
	height: rem(40px);
	display: flex !important;
	align-items: center;
	justify-content: center;
	border-radius: rem(6px);
	font-size: rem(14px);
	padding: 0 rem(11px) !important;
	white-space: nowrap;
}

.isSubmitting {
	padding: rem(5px);
	animation: rotating 1s linear infinite;
	>svg {
		width: rem(16px);
		height: rem(16px);
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
