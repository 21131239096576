@import '~@scss/functions';

.addBillingInfoBanner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: rem(16px);
	border-radius: rem(6px);
	border: rem(1px) solid #ff9d2b;
	background-color: #fff7ee;

	&__textBlock {
		display: flex;
		align-items: center;
	}

	&__textHeader {
		margin-bottom: rem(10px);
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__textParagraph {
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
		color: #000;
	}

	&__textImgBlock {
		margin-right: rem(16px);
	}

	&__btn {
		display: flex !important;
		align-items: center;
	}

	&__btnIcon {
		margin-left: rem(5px);
	}
}