@import '~@scss/functions';

.paginationContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row wrap;

	.paginationButton {
		margin: rem(8px) !important;

		&.arrow {
			background: transparent;
			border: none;
			box-shadow: none;

			&:hover {
				background: #fff;
			}
		}

		.arrowLeft {
			transform: rotate(180deg);
		}
	}


	.dropdownButton {
		position: relative;

		&:hover {
			.pageDropdown {
				opacity: 1;
				visibility: visible;
			}
		}

		.pageDropdown {
			opacity: 0;
			visibility: hidden;
			position: absolute;
			padding: 1em;
			border-radius: rem(6px);
			border: rem(1px) solid #d3e6fb;
			background-color: #ffffff;
			left: 50%;
			transform: translate(-50%);
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			white-space: nowrap;
			transition: opacity .5s;

			&:after, &:before {
				bottom: 100%;
				left: 50%;
				border: solid transparent;
				content: "";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
			}

			&:after {
				border-color: rgba(255, 255, 255, 0);
				border-bottom-color: #ffffff;
				border-width: rem(8px);
				margin-left: rem(-8px);
			}
			&:before {
				border-color: rgba(211, 230, 251, 0);
				border-bottom-color: #d3e6fb;
				border-width: rem(10px);
				margin-left: rem(-10px);
			}

			& > * {
				margin: 0 rem(10px);
			}

			.pageInput {
				width: rem(60px);

				input {
					text-align: center;
				}
			}

			button {
				width: fit-content;
			}
		}
	}
}