@import '~@scss/functions';

.accountMenu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	color: #000000;
	font-size: rem(16px);
	font-weight: 600;
	letter-spacing: rem(0.16px);
	margin-left: rem(16px);
	height: rem(40px);
	border-radius: rem(6px);
	padding-left: rem(11px);
	padding-right: rem(10px);
	cursor: pointer;
	user-select: none;

	&_active {
		background-color: #eaf6ff;
	}

	&__icon {
		margin-left: rem(12px);
		>svg {
			width: rem(12px);
			height: rem(8px);
		}
	}

	&__optionsWrap {
		z-index: 101;
		position: absolute;
		top: rem(40px);
		right: 0;

		&__options {
			margin-top: rem(10px);
			border-radius: rem(6px);
			border: rem(1px) solid #d3e6fb;
			background-color: #ffffff;
			padding: rem(12px) rem(5px) rem(12px) rem(11px);

			&::before {
				margin-top: rem(10px);
				border-width: rem(1px) 0 0 rem(1px);
				transform: translateX(-50%) translateY(-50%) rotate(45deg);
				top: 0;
				right: rem(15px);
				position: absolute;
				content: '';
				background-image: none;
				z-index: 11;
				width: rem(10px);
				height: rem(10px);
				transition: background 0.1s ease;
				border-style: solid;
				border-color: #d3e6fb;
				background-color: #fff;
			}

			&__option {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: rem(158px);
				height: rem(44px);
				cursor: pointer;
				color: #000000;
				font-size: rem(16px);
				font-weight: 500;
				letter-spacing: rem(0.16px);
				padding-left: rem(8px);

				&:hover {
					border-radius: rem(6px);
					background-color: #e4eefe;
					font-weight: 700;
				}

				&__icon {
					display: flex;
					align-items: center;
					justify-content: center;
					background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
					width: rem(28px);
					height: rem(28px);
					border-radius: 50%;
					margin-right: rem(12px);

					&_danger {
						background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
					}
				}
			}
		}
	}

	&__skeleton {
		width: rem(200px);
		margin-left: rem(16px);
	}

	&__avatar {
		width: rem(32px);
		height: rem(32px);
		margin-right: rem(11px);
	}
}