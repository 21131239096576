@import '~@scss/functions';

.globalHostname {
	color: #104378;
	font-size: rem(14px);
	font-weight: 600;
	letter-spacing: rem(0.14px);

	&__loadingMock {
		width: rem(155px);
		height: rem(6px);
		border-radius: rem(1px);
		background-color: #e4eefe;
	}

	&__content {
		display: flex;
		align-items: center;

		&__hostnameText {
			position: relative;
			margin-right: rem(3px);
		}

		&__copyIcon {
			cursor: pointer;
		}
	}
}