@import '~@scss/functions';

.ldsSpinner {
	color: inherit;
	display: inline-block;
	position: relative;
	width: rem(20px);
	height: rem(20px);
}

.ldsSpinner.ldsSpinnerInverted {
	margin-right: rem(6px);

}
.ldsSpinner.ldsSpinnerInverted div:after {
	background: #fff;
}

.ldsSpinner div {
	transform-origin: rem(10px) rem(10px);
	animation: lds-spinner 1.2s linear infinite;
}
.ldsSpinner div:after {
	content: " ";
	display: block;
	position: absolute;
	top: 0;
	left: rem(9px);
	width: rem(2px);
	height: rem(5px);
	border-radius: 20%;
	background: #000;
}
.ldsSpinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.2s;
}
.ldsSpinner div:nth-child(2) {
	transform: rotate(45deg);
	animation-delay: -1.05s;
}
.ldsSpinner div:nth-child(3) {
	transform: rotate(90deg);
	animation-delay: -0.9s;
}
.ldsSpinner div:nth-child(4) {
	transform: rotate(135deg);
	animation-delay: -0.75s;
}
.ldsSpinner div:nth-child(5) {
	transform: rotate(180deg);
	animation-delay: -0.6s;
}
.ldsSpinner div:nth-child(6) {
	transform: rotate(225deg);
	animation-delay: -0.45s;
}
.ldsSpinner div:nth-child(7) {
	transform: rotate(270deg);
	animation-delay: -0.3s;
}
.ldsSpinner div:nth-child(8) {
	transform: rotate(315deg);
	animation-delay: -0.15s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.3;
	}
}