@import '~@scss/functions';

.wrapper {
	display: flex;
	border-radius: rem(4px);
	height: rem(28px);
	border: rem(1px) solid #d3e6fb;
	box-sizing: border-box;

	&_activeInput {
		border-color: #0c5ce5;
		background-color: #0c5ce5 !important;
		.sideBtn {
			&::before, &::after {
				background-color: #fff !important;
			}
		}
	}

	.sideBtn {
		background-color: transparent;
		outline: none;
		border: none;
		position: relative;
		transition: all 0.3s ease;
		display: flex;
		width: 34%;
		padding: 0;
		cursor: pointer;

		&::before, &::after {
			content: "";
			position: absolute;
			width: 50%;
			height: rem(2px);
			background-color: #104378;
			transition: all 0.3s ease;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}

		&_minus {
			&::before {
				content: none;
			}
		}

		&_plus {
			&::after {
				transform: rotate(90deg);
			}
		}
	}

	input[type="text"] {
		width: 33%;
		border: none;
		text-align: center;
		font-size: rem(14px);
		font-weight: 600;
		border-left: rem(1px) solid #d3e6fb;
		border-right: rem(1px) solid #d3e6fb;
	}
}
