@import '~@scss/functions';

$white: #fff;
$defaultBorderRadius: rem(6px);

.clusterStatusDot {
	position: relative;
	display: inline-block;

	&__iconContainer {
		position: relative;
	}

	&__ldsSpinner {
		margin-left: rem(-4px);
		top: rem(3px);
		margin-right: rem(12px);
		cursor: pointer;
	}

	&__definition {
		position: absolute !important;
		background: #000000 !important;
		opacity: 0.75 !important;
		color: $white !important;
		bottom: rem(12px) !important;
		font-size: rem(14px) !important;
		font-weight: 400 !important;
		letter-spacing: rem(0.14px) !important;
		height: rem(37px) !important;
		border-radius: $defaultBorderRadius !important;
		padding-left: rem(12px) !important;
		display: flex !important;
		align-items: center !important;
		transform: translateX(calc(-50% + 4px)) !important;
	}

	&__dotEl {
		margin-right: rem(16px);
	}

	&__definitionDot {
		margin-right: rem(10px);
	}

	&__rebuilding {
		bottom: rem(18px) !important;
	}
}
