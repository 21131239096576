@import '~@scss/functions';

.environmentFieldList {
	display: flex;
	flex-direction: column;
	margin: rem(-6px) 0;

	.listItem {
		display: flex;
		padding: rem(6px) 0;

		.sections {
			display: flex;
			margin: 0 rem(-20px);
			flex: 1;
		}

		.section {
			display: flex;
			padding: 0 rem(20px);
			flex: 1;

			&_inputCol {
				width: 100%;
				display: flex;
				flex-direction: column;
				flex: 3;
				min-width: 0;

				.input {
					flex: 1;
					display: flex;
					height: rem(46px);
					width: rem(200px);

					& input::placeholder {
						color: #545a76;
					}
				}

			}

			.label {
				flex: 1;
				margin-right: rem(20px);
			}
		}
	}
}

.trashBtn {
	&_hidden {
		visibility: hidden;
	}
}
