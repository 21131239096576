@import '~@scss/functions';

.clusterSettings {
	&__head {
		width: rem(1200px);
		padding-right: rem(39px);
		margin-right: auto;
		margin-left: auto;

		display: flex;
		justify-content: space-between;
		margin-bottom: rem(40px);
		padding-top: rem(20px);

		&__text {
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
		}

		&__btn {
			width: rem(196px);
			height: rem(40px);

			>div >div >div {
				height: rem(40px);
			}
		}
	}

	&__errorMessage {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		top: rem(5px);
		bottom: auto;
		width: auto;
	}

	.decorBorder {
		width: 100%;
		border-top: rem(1px) solid #d4e2f1;
	}

	&__content {

		&_wrapped {
			display: flex;
			margin-right: auto;
			margin-left: auto;
			min-width: 0;
			padding-top: rem(88px);

			&__header {
				background-color: #f8fcff;
				z-index: 99;
				width: 100%;
				position: absolute;
				border-bottom: rem(1px) solid #d4e2f1;

				&_fixed {
					position: fixed;
					top: 0;
					border-top: rem(1px) solid #d4e2f1;
				}
			}

			&__navMenu {
				width: rem(267px);
				display: flex;
				flex-direction: column;
				padding-top: rem(40px);

				&_fixed {
					padding-top: rem(128px);
					position: fixed;
					top: 0;
				}

				&__item {
					display: flex;
					align-items: center;
					width: rem(267px);
					height: rem(39px);
					color: #000000;
					font-size: rem(16px);
					font-weight: 600;
					letter-spacing: rem(0.16px);
					margin-bottom: rem(10px);
					padding-left: rem(18px);
					cursor: pointer;
					transition: all 240ms ease;
					border-radius: rem(6px) 0 0 rem(6px);
					position: relative;
					z-index: 1;
					overflow: hidden;

					&::before {
						position: absolute;
						content: "";
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						background: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
						opacity: 0;
						z-index: -1;
						transition: opacity 240ms ease;
					}

					&.active {
						color: #ffffff;

						&::before {
							opacity: 1;
						}
					}
				}
			}

			&__scrollWrap {
				width: rem(893px);
				padding-left: rem(31px);
				border-left: rem(1px) solid #d4e2f1;
				padding-bottom: 25vh;

				&_fixed {
					margin-left: rem(267px);
				}
			}

			&__body {
				&__blockHead {
					height: rem(60px);
					color: #000000;
					font-size: rem(24px);
					font-weight: 600;
					letter-spacing: rem(0.24px);
					border-bottom: rem(1px) solid #d4e2f1;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: rem(40px);

					&_domains {
						margin-bottom: rem(30px);
					}

					&_spinner {
						float: right;
					}

					&__btn {
						font-size: rem(14px) !important;
						font-weight: 600 !important;
						letter-spacing: rem(0.14px) !important;
						height: rem(32px);

						&_configHttps {
							width: rem(134px);
						}

						&_addDomain {
							margin-right: rem(15px) !important;
							width: rem(116px);
						}
					}
				}

				&__pointDomains {
					width: 100%;
					height: rem(38px);
					line-height: rem(38px);
					border-radius: rem(6px);
					border: rem(1px) solid #d4e2f1;
					background-color: #f8fcfe;
					color: #000000;
					font-size: rem(14px);
					font-weight: 400;
					letter-spacing: rem(0.14px);
					text-align: center;
					margin-bottom: rem(10px);

					span {
						font-weight: 700;
					}
				}

				&__dockerImage {
					margin-bottom: rem(62px);
					padding-top: rem(40px);

					&__inputBlock {
						display: flex;
						justify-content: space-between;

						&__imageUrl {
							width: rem(656px);
							height: rem(46px);
							margin-bottom: 0;

							input {
								height: rem(46px);
								width: rem(465px);
							}

							&__skeleton {
								width: rem(192px);
								left: rem(210px);
							}
						}

						&__imageTag {
							width: rem(168px);
							height: rem(46px);
							margin-bottom: 0;

							input {
								width: rem(168px);
								height: rem(46px);
							}

							&__skeleton {
								left: rem(14px);
								width: rem(64px);
							}

							&__errorLabelClassName {
								left: 0;
							}
						}
					}
				}

				&__domain {
					margin-bottom: rem(60px);
				}

				&__https {
					margin-bottom: rem(60px);
				}

				&__webhook {
					margin-bottom: rem(60px);
					display: flex;

					&__urlInput {
						>div {
							input {
								padding-right: rem(70px) !important;
							}
						}
					}

					&__btnCopy {
						min-width: rem(57px) !important;
						min-height: rem(32px) !important;
						box-shadow: 0 rem(2px) rem(3px) rgba(16, 67, 120, 0.05) !important;
						border-radius: rem(6px) !important;
						border: rem(1px) solid #d3e6fb !important;
						background: #ffffff !important;
						color: #104378 !important;
						font-size: rem(14px) !important;
						font-weight: 600 !important;
						letter-spacing: rem(0.14px) !important;
						text-align: center !important;

						&:hover {
							color: #fff !important;
						}
					}

					&__label {
						display: flex;
						align-items: center;
						width: rem(200px);
						color: #000;
						font-size: rem(16px);
						font-weight: 600;
						letter-spacing: rem(0.16px);
					}
				}

				&__permissions {
					margin-bottom: rem(60px);

					&__table {
						&__userCell {
							display: flex;

							&__avatar {
								margin-right: rem(20px);
								width: rem(42px);
								height: rem(42px);
							}

							div:nth-child(2) {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: left;

								div:nth-child(1) {
									color: rgba(0,0,0, 1);
									font-size: rem(16px);
									font-weight: 400;
									letter-spacing: rem(0.16px);
								}

								div:nth-child(2) {
									color: rgba(0,0,0, 0.5);
									font-size: rem(14px);
									font-weight: 400;
									letter-spacing: rem(0.14px);
								}
							}
						}

						&__userCellIcon {
							margin-right: rem(20px);
						}
					}

					&__mock {
						&_one, &_two {
							height: rem(8px);
							border-radius: rem(2px);
							background-color: #104378;
							opacity: 0.1;
						}

						&_one {
							width: rem(64px);
						}

						&_two {
							width: rem(88px);
						}
					}
				}
			}
		}
	}

	&__addPeopleBtn {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		width: rem(187px);
		height: rem(32px);
		font-size: rem(14px) !important;
		font-weight: 600 !important;
		letter-spacing: rem(0.14px) !important;

		>span:nth-child(1) {
			margin-right: rem(11px);
		}
	}

	&__footerRow {
		td {
			border-bottom: rem(1px) solid #d4e2f1 !important;
		}
	}

	&__positiveStatus, &__negativeStatus {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: rem(6px);
		height: rem(32px);
		padding: rem(8px) rem(11px);
		font-size: rem(14px);
		font-weight: 600;
		letter-spacing: rem(0.14px);
	}

	&__negativeStatus {
		background-color: rgba(255, 83, 42, 0.2);
		color: #e01616;
	}

	&__positiveStatus {
		background-color: rgba(18, 212, 173, 0.2);
		color: #0d886f;
	}
}

.portMapping {
	width: rem(763px);
	height: auto;
	border-radius: rem(16px);
	background-color: #ffffff;
	padding: rem(25px);
	padding-top: 0;

	&__close {
		position: absolute;
		right: 0;
		top: rem(-34px);
		cursor: pointer;
		width: rem(20px);
		height: rem(20px);
	}

	&__icon {
		position: absolute;
		top: rem(-43px);
		width: rem(86px);
		height: rem(86px);
		left: 50%;
		transform: translateX(-50%);
	}

	&__header {
		padding-top: rem(59px);
		text-align: center;
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
		margin-bottom: rem(20px);
	}

	&__msg {
		width: 100%;
		padding: rem(10px);
		border: rem(1px) solid #d4e2f1;
		border-radius: rem(6px);
		background-color: #f8fcfe;
		margin-bottom: rem(25px);
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			max-width: rem(565px);
			color: #545a76;
			color: #000000;
			font-size: rem(14px);
			font-weight: 400;
			letter-spacing: rem(0.14px);
			text-align: center;
		}

		&_error {
			height: rem(62px);
			background-color: #fff3f3;
			border: rem(1px) solid #ff3232;
		}

		&_warning {
			border: rem(1px) solid #ff9d2b;
			background-color: #fff7ee;
		}
	}

	&__form {
		max-width: fit-content;
		margin: auto;

		&__ports {
			display: flex;
			flex-direction: row;

			>div {
				color: #545a76;
				font-size: rem(18px);
				font-weight: 400;
				letter-spacing: rem(0.18px);
				line-height: rem(24px);
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				max-width: 50%;
				overflow: hidden;
				flex: 1;
				margin: rem(4px) 0;
				width: rem(254px);
				height: rem(46px);
				border-radius: rem(6px);
				border: rem(1px) solid #d4e2f1;
				background-color: #f7fbff;

				&:first-child {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

				&:last-child {
					border-left-width: 0;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}

			&__private {
				background-color: #fff !important;

				input {
					border: none;
					width: 100%;
					height: 100%;
					text-align: center;
					font-family: Raleway, sans-serif;
					font-size: rem(16px);
					font-weight: 400;
					font-style: normal;
					letter-spacing: rem(0.16px);
					line-height: rem(22px);
					color: #545a76;

					&:active, &:focus {
						outline: none;
						border: none;
					}
				}

				&_error {
					border-color: #ff3232 !important;
					border-left: rem(1px) solid #ff3232 !important;
					border-radius: rem(6px) !important;
				}
			}
		}

		&__headers {
			display: flex;
			flex-flow: row nowrap;

			> div {
				flex: 1;
				width: 100%;
				text-align: center;
				color: #000000;
				font-size: rem(16px);
				font-weight: 600;
				letter-spacing: rem(0.16px);
				margin-bottom: rem(2px);
			}
		}

		&__btnContainer {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-top: rem(32px);
			margin-bottom: rem(9px);

			&__disableBtn {
				color: #ffffff;
				font-size: rem(16px);
				font-weight: 700;
				letter-spacing: rem(0.16px);
				display: block !important;
				margin: 0 rem(6.5px) !important;
				width: rem(148px);
				height: rem(40px);
				background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%) !important;

				&::before {
					background: #ce3b17 !important;
				}
			}

			&__mainBtn {
				color: #ffffff;
				font-size: rem(16px);
				font-weight: 700;
				letter-spacing: rem(0.16px);
				display: block !important;
				margin: 0 rem(6.5px) !important;
				width: rem(186px);
				height: rem(40px);
				background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%) !important;
			}
		}
	}

	&__disableAlert {
		margin-top: rem(20px);
	}
}

.addDomain {
	width: rem(404px);
	height: auto;
	border-radius: rem(16px);
	background-color: #ffffff;
	padding-bottom: rem(25px);

	&__close {
		position: absolute;
		right: 0;
		top: rem(-34px);
		cursor: pointer;
		width: rem(20px);
		height: rem(20px);
	}

	&__icon {
		position: absolute;
		top: rem(-43px);
		left: rem(159px);
		width: rem(86px);
		height: rem(86px);
	}

	&__header {
		padding-top: rem(59px);
		text-align: center;
		color: #000000;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__subHeader {
		width: rem(354px);
		height: rem(58px);
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		color: rgba(0,0,0,0.5);
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
		line-height: rem(20px);
		margin-bottom: rem(22px);
	}

	&__msg, &__errMsg {
		padding: rem(8px);
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		line-height: rem(17px);
		margin-top: rem(20px);
		margin-bottom: rem(20px);
		margin-right: auto;
		margin-left: auto;
		width: rem(317px);
		height: rem(54px);
		border-radius: rem(6px);
		font-size: rem(14px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
	}

	&__msg {
		width: rem(354px);
		height: rem(70px);
		border: rem(1px) solid #d4e2f1;
		background-color: #f8fcfe;
		color: #545a76;
		margin-bottom: rem(34px);
	}

	&__errMsg {
		width: rem(354px);
		height: rem(54px);
		border: rem(1px) solid #ff3232;
		background-color: #fff3f3;
		color: #000000;
	}

	&__saveBtn {
		width: rem(164px);
		height: rem(40px);
		color: #ffffff;
		font-size: rem(16px);
		font-weight: 700;
		letter-spacing: rem(0.16px);
		margin-left: auto !important;
		margin-right: auto !important;
		display: block !important;
		margin-top: rem(40px);
	}

	&__dropDown {
		position: relative;
		height: rem(1px);
		width: 100%;
		background: #d3e6fb;
		margin-bottom: rem(35px);
		margin-top: rem(48px);

		>span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			filter: blur(0);
			-webkit-filter: blur(0);
		}
	}

	&__cnameTarget {
		width: rem(352px);
		margin-bottom: rem(40px);
		margin-right: auto;
		margin-left: auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;

		&__label {
			width: 100%;
			color: #000000;
			font-size: rem(16px);
			font-weight: 600;
			letter-spacing: rem(0.16px);
			margin-bottom: rem(28px);
		}

		&__value {
			width: 100%;
			text-align: center;
			color: #545a76;
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);
		}
	}

	&__errorLabel {
		left: 0;
		top: rem(88px);
	}
}

.regularInput {
	width: rem(354px);
	height: auto;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: rem(20px);
	flex-wrap: wrap;

	div:first-child {
		width: 100%;
	}

	input {
		width: 100%;
		margin-top: rem(16px);
	}

	>span {
		top: rem(45px);
	}
}

.section {
	min-height: 25vh;
}

.noUsers {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__icon {
		width: rem(50px);
		height: rem(50px);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: rem(20px);
		margin-bottom: rem(16px);
		background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);

		svg {
			width: rem(24px);
			height: rem(24px);
		}
	}

	&__header {
		color: #000000;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
	}
}


.envVars {

	// .listItem
	>div {
		padding: 0 !important;

		// .sections
		>div {
			margin: 0 !important;
			align-items: flex-start;

			// .section
			>div {
				&:nth-last-child(1) {
					margin-right: rem(3px) !important;
				}

				width: rem(397px);
				height: rem(86px);
				flex: none !important;
				padding: 0 !important;
				margin-bottom: 0 !important;
				margin-right: rem(30px) !important;

				>label {
					width: rem(149px);
					flex: none !important;
					margin-right: 0 !important;
				}

				// .section_inputCol
				>div {
					// basicInput
					>div {
						flex: none !important;
						height: rem(46px) !important;
						width: rem(248px) !important;

						// error svg
						>span {
							top: rem(7px);
						}
					}
				}
			}
		}
	}
}

.ports {

	>div {
		// .inputs
		>div:nth-child(1) {
			width: rem(827px);
			min-width: auto;
			margin: 0 !important;
			align-items: flex-start;

			// .section
			>div {
				&:nth-last-child(1) {
					margin-right: rem(3px) !important;
				}

				width: rem(254px);
				height: rem(86px);
				flex: none !important;
				padding: 0 !important;
				margin-bottom: 0 !important;
				margin-right: rem(30px);

				>label {
					width: rem(149px);
					flex: none !important;
					margin-right: 0 !important;
				}

				>div {

					>div:nth-child(1) {
						flex: none !important;
						height: rem(46px) !important;
						width: rem(105px) !important;
					}
				}
			}
		}

		// .trashBtn
		>div:nth-child(2) {
			margin: 0 0 rem(40px);
			padding: 0;
		}
	}
}

.permissionsTableItem {
	&__col {
		&__removeIcon {
			cursor: pointer;
		}
	}
}
