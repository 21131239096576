@import '~@scss/functions';

.psswrdProgress {
	width: rem(367px);
	margin-left: rem(200px);
	position: absolute;
	top: rem(62px);
	justify-content: flex-start;
	display: flex;

	.psswrdProgressBlock {
		width: rem(82px);
		height: rem(4px);
		border-radius: rem(3px);
		background-image: linear-gradient(to top, #18d6a6 0%, #00cdc8 100%);
		margin-right: rem(13px);
		&:last-child {
			margin-right: 0;
		}

		&_weak {
			background-image: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
		}
	}
}