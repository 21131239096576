@import '~@scss/functions';

.registryIcon {
	position: relative;
	align-items: center;
	border-radius: 50%;
	background-color: #eaf6ff;
	width: rem(86px);
	height: rem(86px);
	display: flex;
	justify-content: center;
	align-content: center;

	.icon {
		width: rem(30px);
	}
}

.tooltip {
	position: absolute;
	color: #FFFFFF;
	background: rgba(0,0,0,0.75);
	border-radius: rem(6px);
	width: max-content;
	max-width: rem(500px);
	font-weight: 400;
	left: rem(-12px);
	bottom: rem(55px);
	padding: rem(11px) rem(15px);

	&::before {
		right: auto;
		left: rem(13px);
		position: absolute;
		content: '';
		width: rem(10px);
		height: rem(6px);
		transition: background 0.1s ease;
		bottom: rem(-10px);
		border-left: solid transparent rem(10px);
		border-right: solid transparent rem(10px);
		border-top: solid rgba(0,0,0,0.75) rem(10px);
		z-index: 1001;
	}
}
