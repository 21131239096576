@import '~@scss/functions';

.priceBarGroup {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	.priceBar {
		&:nth-child(odd) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:nth-child(even) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border-left: 0;
		}

		.titleText {
			font-weight: 600;
			color: #000;
		}

		.priceText {
			color: #0c5ce5;
			font-weight: 600;
		}
	}
}

.priceBar {
	width: 100%;
	height: rem(76px);
	display: flex;
	align-items: center;
	padding: 0 rem(20px);
	border: rem(1px) solid #d4e2f1;
	border-radius: rem(15px);
	background-color: #fff;

	.iconWrapper {
		margin-right: rem(20px);
		width: rem(28px);
		height: rem(28px);
		background-image: linear-gradient(180deg, #0c5ce5 0%, #0853d3 100%);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.titleWrapper {
		flex: 1;
		color: #000000;
	}

	.priceWrapper {
		color: #0c5ce5;
	}

	.titleWrapper, .prieWrapper {
		font-family: Raleway, sans-serif;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}
}