@import '~@scss/functions';

.resourcesCard {
	height: 100%;
	position: relative;

	&__head {
		display: flex;
		justify-content: space-between;
	}

	&__headTitle {
		color: #000000;
		font-family: Raleway, sans-serif !important;
		font-size: rem(16px);
		font-weight: 600;
		letter-spacing: rem(0.16px);
	}

	&__headBtn {
		font-family: Raleway, sans-serif !important;
		font-size: rem(14px) !important;
		font-weight: 600 !important;
		letter-spacing: rem(0.14px);
	}

	&__skeleton {
		width: rem(22px);
		margin-top: rem(18px);
		margin-bottom: rem(16px);
	}

	&__topBarSkeleton {
		width: rem(28px);
		margin-top: rem(8px);
	}
}

.hardwareList {
	display: flex;
	flex-direction: row;

	&__item {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		text-align: center;

		.type {
			font-size: rem(16px);
			color: #545a76;
			line-height: rem(22px);
			text-transform: uppercase;
		}

		.value {
			font-size: rem(28px);
			color: #050d29;
			line-height: rem(40px);
			font-weight: 700;
			text-transform: uppercase;
		}

		&:not(:last-child) {
			border-right: rem(1px) solid #d4e2f1;
		}
	}
}

.planPriceBar {
	display: flex;
	justify-content: space-between;
	background-color: #f9fcfe;
	border-bottom: rem(1px) solid #d4e2f1;
	&__item {
		flex: 1;
		display: flex;
		justify-content: space-between;

		.label {
			text-transform: uppercase;
			font-weight: 400;
			color: #545a76;
		}

		.value {
			letter-spacing: rem(0.16px);
			font-size: rem(16px);
			text-transform: uppercase;
			&.regular {
				color: #000;
				font-weight: 400;
			}

			&.price {
				color: #0c5ce5;
				font-weight: 600;
			}
		}
	}
}

.btn {
	height: rem(32px);
}