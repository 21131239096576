@import '~@scss/functions';

.clusterConsole {

	&__head {
		display: flex;
		justify-content: space-between;
		margin-bottom: rem(40px);
		padding-top: rem(20px);

		&__text {
			color: #050d29;
			font-size: rem(32px);
			font-weight: 700;
			line-height: rem(40px);
		}
	}

	&__content {
		border: rem(1px) solid #d4e2f1;
		background: #FFFFFF;
		border-radius: rem(15px);

		&__head {
			display: flex;
			justify-content: left;
			align-items: center;
			height: rem(52px);
			padding-left: rem(60px);
			padding-right: rem(60px);
			overflow-x: auto;

			&__nodeRegion {
				height: 100%;
				padding-right: rem(16px);
				padding-left: rem(9px);
				display: flex;
				align-items: center;
				color: #000000;
				font-size: rem(16px);
				font-weight: 400;
				letter-spacing: rem(0.16px);
				line-height: rem(22px);
				text-transform: uppercase;
				cursor: pointer;
				border-bottom: rem(2px) solid transparent;
				>i {
					width: rem(21px);
					height: rem(15px);
					margin-right: rem(12px);
				}
				&_active {
					border-bottom: rem(2px) solid #00a6ff;
					font-weight: 600;
				}
			}
		}

		&__divider {
			border-bottom: rem(1px) solid #d4e2f1;
		}

		&__body {
			height: rem(483px);
			& > div[id='#terminal-container'] {
				padding-right: rem(39px);
			}
			&__terminalWrapper {
				margin: rem(40px) rem(60px) 0;
				display: flex;
				justify-content: left;
				align-items: center;
				height: rem(391px);

				&_hidden {
					display: none;
				}
			}
			&__noConnection {
				display: flex;
				flex-direction: column;
				align-items: center;
				color: #050d29;
				justify-content: center;
				align-content: center;

				&__logo {
					position: relative;
					max-width: 100%;
					width: 100%;
					margin-top: rem(60px);
					margin-bottom: rem(60px);

					img {
						position: relative;
						left: rem(446px);
						height: rem(224px);
						width: rem(324px);
					}
				}
				&__header {
					font-size: rem(32px);
					font-weight: 700;
					line-height: rem(40px);
					margin-bottom: rem(10px);
				}
				&__subheader {
					margin-bottom: rem(60px);
					font-size: rem(16px);
					font-weight: 400;
					letter-spacing: rem(0.16px);
					color: #000;
				}
			}
		}

		&__noPermissionMsg {
			height: rem(538px);
			padding: rem(20px) 0 0 rem(20px);
		}
	}
}

#terminal-container .terminal.xterm {
	height: 100%;
}
#terminal-container .xterm-viewport {
	height: 100% !important;
}