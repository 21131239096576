@import '~@scss/functions';

$defaultBorderRadius: rem(6px);
$darkBlue: #104378;
$white: #ffffff;
$black: #000;
$borderColor: #d3e6fb;

.clusterNodes {
	white-space: pre;
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;

	.ui.pointing.label::before, .ui[class*="pointing above"].label::before {
		left: 12%;
	}

	.flagIcons {
		margin-right: rem(7px);
		height: rem(19px);
		width: rem(26px);
	}

	.globalNodes, .noneNodes {
		display: inline-flex;
		margin-right: rem(8px);
	}

	.globalNodes {
		>svg {
			width: rem(26px);
			height: rem(26px);
		}
	}

	.noneNodes {
		display: flex;
		align-items: center;
		justify-content: center;
		border: rem(5px) solid #ff5f3a;
		height: rem(26px);
		width: rem(26px);
		border-radius: 50%;
		>svg {
			width: rem(10px);
			height: rem(10px);
		}
	}

	>span {
		color: $black;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);
	}
	.clusterNodes__descr {
		position: absolute !important;
		top: rem(20px) !important;
		left: rem(-10px) !important;
		color: $darkBlue;
		font-size: rem(14px);
		width: rem(167px);
		border-radius: $defaultBorderRadius;
		border: rem(1px) solid $borderColor;
		background-color: $white;
		z-index: 1000;
		padding: rem(15px) rem(10px) rem(10px) rem(12px);
		font-weight: 400;
		letter-spacing: rem(0.14px);
		&::before {
			left: 11% !important;
		}

		>div {
			margin-bottom: rem(12px);
			&:last-child {
				margin-bottom: 0;
			}
		}

		&__origin {
			font-size: rem(16px);
			font-weight: 400;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);
			text-transform: uppercase;
			float: left;
		}

		&__amount {
			display: table-cell;
			float: right;
			text-align: center;
			width: rem(38px);
			height: rem(24px);
			border-radius: rem(4px);
			border: rem(1px) solid #dae6f2;
			background-color: #ffffff;
			color: $darkBlue;
			font-size: rem(16px);
			font-weight: 500;
			letter-spacing: rem(0.16px);
			line-height: rem(22px);
		}
	}
}