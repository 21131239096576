@import '~@scss/functions';

.cluster {
	&__hostnameField, &__dockerImage, &__nodes, &__metrics, &__ctrls {
		>div {
			padding: 0;

			a {
				display: flex;
				align-items: center;
				height: 100%;
				width: 100%;
				padding-left: rem(20px);
				padding-right: rem(20px);
				outline: none;
			}
		}
	}

	&__hostnameField {
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(0.16px);

		&__domains {
			display: flex;
			flex-direction: column;


			&__domainName {
				color: #000;
			}

			&__customDomainName {
				opacity: 0.5;
				color: #000000;
				font-size: rem(14px);
				font-weight: 400;
				letter-spacing: rem(0.14px);
			}
		}
	}

	&__dockerImage {
		>div {
			>a {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				>div:nth-child(2) {
					opacity: 0.5;
					color: #000000;
					font-size: rem(14px);
					font-weight: 400;
					letter-spacing: rem(0.14px);
				}
			}
		}
	}

	&__dockerImageName {
		color: #000;
		letter-spacing: rem(0.16px);
	}

	&__pullAndDeployBtn {
		width: rem(130px);
		height: rem(32px);
		cursor: pointer;

		>div {
			>div {
				>div {
					font-size: rem(14px);
					font-weight: 600;
					letter-spacing: rem(0.14px);
				}
			}
		}
	}

	&__ctrls {
		cursor: pointer;
	}
}