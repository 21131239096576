@import '~@scss/functions';

.disabledReg {
	&__messageBlock {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 50px;

		&__logo {
			display: flex;
			width: 100%;
			justify-content: center;
			>div {
				margin-left: rem(57px);
				width: rem(268px);
				height: rem(400px);
				background: url("~@images/logo.reset.png") no-repeat;
				background-size: contain;
				position: relative;
			}
		}

		&__title {
			font-size: rem(32px);
			align-items: center;
			justify-content: center;
		}
		&__subTitle {
			align-items: center;
			justify-content: center;
			font-size: rem(16px);
			margin-top: rem(10px);

			&__link {
				cursor: pointer;
			}
		}
	}
	&__backToLoginBtn {
		display: none;
	}
}

@media (max-width: 768px) {
	.disabledReg {
		width: rem(375px) !important;
		margin-left: auto;
		margin-right: auto;

		&__header {
			height: auto !important;
			padding-left: rem(16px) !important;
			padding-top: rem(30px) !important;
			padding-bottom: 0 !important;
			margin-bottom: rem(32px) !important;
			> button {
				display: none !important;
			}
		}

		&__messageBlock {
			&__title {
				font-size: rem(18px);
				font-weight: 600;
			}
			&__subTitle {
				font-size: rem(14px);
			}
		}
		&__backToLoginBtn {
			display: block;
			margin-top: rem(40px);
			text-align: center;
			text-decoration: underline;
			font-weight: 400;
			font-style: normal;
			letter-spacing: rem(0.16px);
			line-height: normal;
			cursor: pointer;
		}
	}
}

@media screen and (min-width: 1920px) {
	.disabledReg {
		width: rem(1360px) !important;
		margin-left: auto;
		margin-right: auto;
	}
}