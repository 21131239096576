.wrapper {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;

	.content {
		width: 100%;
		height: 100%;
	}
}