@import '~@scss/functions';

.billingMain {
	&__contentWrapper {
		width: rem(1200px);
		margin-left: auto;
		margin-right: auto;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: rem(57px);
		margin-bottom: rem(60px);
	}

	&__headerTitle {
		color: #050d29;
		font-size: rem(32px);
		font-weight: 700;
		line-height: rem(40px);
	}

	&__headerRightElem {
		display: flex;
		flex-direction: column;
	}

	&__headerRightElemTitle {
		color: #545a76;
		font-family: Raleway, sans-serif;
		font-size: rem(16px);
		font-weight: 400;
		letter-spacing: rem(1.5px);
		line-height: rem(22px);
		text-transform: uppercase;
	}

	&__headerRightElemValue {
		color: #050d29;
		font-family: Raleway, sans-serif;
		font-size: rem(32px);
		font-weight: 700;
		line-height: rem(40px);

		&__skeleton {
			width: rem(224px);
			margin-top: rem(12.8px);
			margin-bottom: rem(22.4px);
		}
	}

	&__contentBlock {
		margin-bottom: rem(30px);
	}

	&__doubleTables {
		display: flex;
		justify-content: space-between;
	}

	&__halfBlockTable {
		width: 50%;

		&:not(:first-child) {
			margin-left: rem(30px);
		}
	}

	&__error {
		position: fixed;
		right: rem(20px);
		bottom: rem(100px);
	}
}