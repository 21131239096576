@import '~@scss/functions';

.spinnerWrapper {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	width: 100%;
	height: 100%;
	flex-direction: column;

	.spinner {
		width: rem(60px);
		height: rem(60px);
	}
}